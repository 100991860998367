<template>
  <v-btn-toggle v-model="indexButton" @change="handleIndexChange" filled :rounded="rounded" class="ml-2">
    <v-btn :color="(!!value || !required) ? '' : 'error'" v-for="item in items" :key="item[itemValue]">
      <v-icon>{{item[itemIcon]}}</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  data: function () {
    return {
      indexButton: undefined,
      localValue: ""
    };
  },
  props: {
    items: {
      type: Array,
      default: [],
    },
    itemValue: {
      type: String,
    },
    itemIcon: {
      type: String,
      default: "icon"
    },
    multiple: {
      type: Boolean,
    },
    returnObj: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: undefined
    },
    rounded: {
      type: Boolean,
      default: true
    }
  },
  mounted: function(){
    this.calcIndexifItemPresent()
  },
  methods: {
    calcIndexifItemPresent(){
      let count = 0
      if(!!this.value && !!this.items){
        this.items.forEach((entry) => {
          if(!this.returnObj && this.value == entry.name)
            this.indexButton = count
          else if(this.returnObj && this.value == entry)
            this.indexButton = count  
          count++  
        })
      }
    },
    handleIndexChange(){
      this.localValue = this.items[this.indexButton][this.itemValue]
      this.$emit('input', this.localValue)
    }
  },
  computed:{},
  watch:{
    value(newVal){
      this.calcIndexifItemPresent()
    }
  }

};
</script>

<style scoped>
</style>