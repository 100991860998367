<template>
  <v-row no-gutters class="py-1">
    <v-col cols="auto">
      <LeftSideMenu
        v-model="selectedItem"
        :items="menuItems"
        identifier-key="name"
        @item-click="handleMenuItemsClick"
        divided
      ></LeftSideMenu>
    </v-col>
    <v-col class="pa-3">
      <BarberLocalDataSheetsList
        v-show="selectedItem.name == 'barber'"
        :customer="customer"
      ></BarberLocalDataSheetsList>
      <BeuticianLocalDataSheetsList
        v-show="selectedItem.name == 'beutician'"
        :customer="customer"
      ></BeuticianLocalDataSheetsList>
      <PmuLocalDataSheetsList
        v-show="selectedItem.name == 'pmu'"
        :customer="customer"
      ></PmuLocalDataSheetsList>
    </v-col>
  </v-row>
</template>

<script>

import BarberLocalDataSheetsList from '../dataSheets/BarberLocalDataSheetsList.vue'
import BeuticianLocalDataSheetsList from '../dataSheets/BeuticianLocalDataSheetsList.vue'
import PmuLocalDataSheetsList from '../dataSheets/PmuLocalDataSheetsList.vue'
import LeftSideMenu from "@/components/common/LeftSideMenu.vue";
import customerForm from '@/services/customers/customer.form.js'

export default {
  name: 'DataSheetFormTab',
  components: {
    BarberLocalDataSheetsList,
    BeuticianLocalDataSheetsList,
    PmuLocalDataSheetsList,
    LeftSideMenu
  },
  data: function() {
    return {
      selectedItem: {
          title: "Parrucchiere",
          name: "barber",
          component: "BarberLocalDataSheetsList",
      },
      customer: {},
      menuItems: [
        {
          title: "Parrucchiere",
          name: "barber",
          component: "BarberLocalDataSheetsList",
        },
        {
          title: "Estetisti",
          name: "beutician",
          component: "BeuticianLocalDataSheetsList",
        },
        {
          title: "PMU",
          name: "pmu",
          component: "PmuLocalDataSheetsList",
        },
      ]
    }
  },
  mounted: function() {
    let self = this
    this.customer = customerForm.customer
    customerForm.on('update', function(data) {
      self.customer = customerForm.customer
    })
  },
  methods: {
    handleMenuItemsClick(item) {
      this.selectedItem = item
    }
  }
}
</script>

<style>

</style>