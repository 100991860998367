<template>
  <div>
    <div class="d-flex justify-end align-center">
      <v-tooltip allow-overflow bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn 
            icon 
            v-on="on" 
            v-bind="attrs"
            class="mx-2"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>Mostra filtri</span>
      </v-tooltip>
      <v-tooltip allow-overflow bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn 
            icon 
            v-on="on" 
            v-bind="attrs"
            class="mx-2"
            :disabled="!selectedDataSheet || selectedDataSheet.length == 0"
          >
            <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
          </v-btn>
        </template>
        <span>Duplica scheda</span>
      </v-tooltip>
      <v-tooltip allow-overflow bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn 
            icon 
            v-on="on" 
            v-bind="attrs"
            class="mx-2"
            @click="openDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Aggiungi scheda</span>
      </v-tooltip>
    </div>
    <TypeDataTable
      v-model="selectedDataSheet"
      :headers="headers"
      :items="dataSheets"
      loading-text="Caricamento schede tecniche ..."
      :translator="(fieldName, fieldValue) => {
        if(fieldName == 'operator' && !!fieldValue) return fieldValue.firstname + ' ' + fieldValue.lastname
      }"
      :loading="loading"
      :page.sync="page"
      :row-per-page.sync="rowPerPage"
      :total-pages.sync="totalPages"
      :show-select="false"
      disbale-pagination-on-loading
      @edit="handleEdit"
      @delete="deleteDataSheet"
    ></TypeDataTable>
    <StandardDialog
      v-model="newFormDialog"
      :dialog-height="null"
      title="Nuova scheda tecnica"
      :persistent="loadingCreate"
    >
      <BeuticianDataSheetsForm
        v-model="newDataSheet"
        set-operator
      ></BeuticianDataSheetsForm>
      <template v-slot:footer-actions>
        <v-btn text @click="newFormDialog = false" :disabled="loadingCreate" color="error">
          Chiudi
        </v-btn>
        <v-btn text @click="createDataSheet" :loading="loadingCreate" color="default">
          Salva
        </v-btn>
      </template>
    </StandardDialog>
    <StandardDialog
      v-model="editFormDialog"
      :dialog-height="null"
      title="Modifica scheda tecninca"
      :persistent="loadingUpdate"
    >
      <BeuticianDataSheetsForm
        v-model="editDataSheet"
        set-operator
      ></BeuticianDataSheetsForm>
      <template v-slot:footer-actions>
        <v-btn text @click="editFormDialog = false" :disabled="loadingUpdate" color="error">
          Chiudi
        </v-btn>
        <v-btn text @click="updateDataSheet" :loading="loadingUpdate" color="default">
          Salva
        </v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import StandardDialog from '@/components/common/StandardDialog.vue'
import BeuticianDataSheetsForm from './BeuticianDataSheetsForm.vue'
import dataSheetService from '@/services/dataSheets/dataSheets.service.js'
import customerForm from '@/services/customers/customer.form.js'

export default {
  name: 'BeuticianDataSheetsList',
  components: {
    TypeDataTable,
    StandardDialog,
    BeuticianDataSheetsForm
  },
  data: function() {
    return {
      dataSheets: [],
      headers: [],
      loading: false,
      page: 1,
      rowPerPage: 200,
      totalPages: 1,
      newFormDialog: false,
      newDataSheet: {},
      selectedDataSheet: undefined,
      loadingCreate: false,
      editDataSheet: { },
      editFormDialog: false,
      loadingUpdate: false
    }
  },
  props: {
    customer: {}
  },
  mounted: function() {
    this.loadHeaders().then(() => {
      this.loadDataSheets()
    })
  },
  methods: {
    async loadHeaders() {
      this.headers = await dataSheetService.headers('beutician')
    },
    loadDataSheets() {
      this.dataSheets = []
    },
    openDialog() {
      this.newFormDialog = true
    },
    getDataSheetKey() {
      let key = 0
      for(let i = 0; i < this.dataSheets.length; i += 1) {
        if(this.dataSheets[i].key > key) {
          key = this.dataSheets[i].key
        }
      }
      return key + 1
    },
    handleEdit(dataSheet) {
      if(!!dataSheet.fromDate) dataSheet.fromDate = new Date(dataSheet.fromDate)
      if(!!dataSheet.toDate) dataSheet.toDate = new Date(dataSheet.toDate)
      if(!!dataSheet.operatorId) dataSheet.operatorId = parseInt(dataSheet.operatorId)
      this.editDataSheet = dataSheet
      this.editFormDialog = true
    },
    createDataSheet() {
      this.newDataSheet['key'] = this.getDataSheetKey()
      this.newDataSheet['type'] = 'beutician'
      this.dataSheets.push(this.newDataSheet)
      customerForm.updateField('beuticianDataSheets', this.sharedDataSheets)
      this.newFormDialog = false
    },
    updateDataSheet() {
      for(let i = 0; i < this.dataSheets.length; i += 1) {
        if(this.dataSheets[i].key == this.editDataSheet.key) {
          this.$set(this.dataSheets, i, this.editDataSheet)
        }
      }
      customerForm.updateField('beuticianDataSheets', this.sharedDataSheets)
      this.editFormDialog = false
    },
    deleteDataSheet(dataSheet) {
      let confirmed = confirm('Sei sicuro di volere eliminare la scheda tecnica?')

      if(confirmed) {
        for(let i = 0; i < this.dataSheets.length; i += 1) {
          if(this.dataSheets[i].key == dataSheet.key) {
            this.dataSheets.splice(i, 1)
          }
        }
      }
      customerForm.updateField('beuticianDataSheets', this.sharedDataSheets)
    }
  },
  computed: {
    sharedDataSheets() {
      return this.dataSheets.map(dataSheet => {
        let returnObject = {...dataSheet}
        returnObject['operatorId'] = !!returnObject['operator'] ? returnObject['operator']['id'] : undefined
        delete returnObject['operator']
        delete returnObject['key']
        return returnObject
      })
    }
  },
  watch: {
    page() {
      this.loadDataSheets()
    },
    rowPerPage() {
      this.loadDataSheets()
    },
  }
}
</script>

<style>

</style>