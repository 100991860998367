<template>
  <div class="d-flex justify-center">
    <StandardDialog
      v-model="activateCardDialog"
      :dialog-height="null"
      title="Attiva carta"
      dialog-width="500px"
      v-if="!cardActive"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn color="primary" class="back-color-gradient" v-on="on" v-bind="attrs"
          >Attiva la tessera</v-btn
        >
      </template>
      <div class="d-flex justify-center align-center">
        <ManualBarcodeInput
          :opened.sync="manualBarcodeInsert"
          v-model="insertedBarcode"
          @confirm="handleConfirmBarcode"
          :close-on-confirm="false"
          scan-your-barcode-message="Scannerizza la tessera ..."
          type=""
        ></ManualBarcodeInput>
      </div>
    </StandardDialog>
    <div v-else class="d-flex justify-center align-center flex-column">
        <div class="text-caption">Numero Tessera</div>
        <div class="text-h3">{{ confirmedBarcode }}</div>
      <VueBarcode
        v-model="confirmedBarcode"
        height="35px"
        :display-value="false"
      ></VueBarcode>
      <div class="text-body">
        <span class="font-weight-bold">Rimanente:</span>
        <span v-if="remaining != null && remaining != undefined" class="font-weight-light"> {{ remaining }} € </span>
        <span v-else class="font-weight-light"> 0 € </span>
      </div>
      <NumericKeyboardInput
        v-model="serviceDiscounts"
        class="mt-10"
        label="Sconti sui servizi"
        prepend-icon="mdi-percent"
        live
        v-debounce="updateServiceDiscount"
        :min="0"
        :max="100"
      ></NumericKeyboardInput>
      <NumericKeyboardInput
        v-model="productsDiscounts"
        class="mt-5"
        label="Sconti sui prodotti"
        prepend-icon="mdi-percent"
        live
        v-debounce="updateProductDiscount"
        :min="0"
        :max="100"
      ></NumericKeyboardInput>
      <div class="mt-10 d-flex">
        <v-btn color="error" text @click="deactivateCard">
          <span v-if="!!customerId">Disattiva</span>
          <span v-else>Resetta</span>
        </v-btn>
        
        <v-btn
          @click="charginDialog"
          v-if="showLoadButton"
          color="success"
          text
          lass="ml-4"
        > Ricarica</v-btn>

        <ErrorDialogComponent
        v-if="showErrorDialog"
        :open-dialog="showErrorDialog"
        title="Cassa"
        error-message="Cassa ancora chiusa"
        type="error"
        @close-error="showErrorDialog = false">
        </ErrorDialogComponent>

        <StandardDialog
          v-model="loadCardDialog"
          :dialog-height="null"
          title="Ricarica carta"
          dialog-width="500px"
        >
          <NumericKeyboardInput
            v-model="loadValue"
            class="mt-5"
            label="Ricarica di"
            prepend-icon="€"
            liveù
            :min="0"
          ></NumericKeyboardInput>
          <template v-slot:footer-actions>
            <v-btn color="error" text @click="loadCardDialog = false"
              >Chiudi</v-btn
            >
            <v-btn
              color="default"
              text
              @click="confirmLoad"
              :disabled="!loadValue"
            >Conferma</v-btn
          >
          </template>
        </StandardDialog>
      </div>
    </div>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import ManualBarcodeInput from "@/components/common/ManualBarcodeInput.vue";
import NumericKeyboardInput from "@/components/common/NumericKeyboardInput.vue";
import debounce from "@/mixins/common/debounce.js";
import customerCardsService from "@/services/customers/customerCards.service.js";
import VueBarcode from "vue-barcode";
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";
import ErrorDialogComponent from "@/components/common/errorComponent/ErrorDialogComponent.vue"

export default {
  name: "CustomerCardForm",
  components: {
    StandardDialog,
    ManualBarcodeInput,
    VueBarcode,
    NumericKeyboardInput,
    ErrorDialogComponent
  },
  mixins: [debounce],
  data: function() {
    return {
      activateCardDialog: false,
      manualBarcodeInsert: false,
      insertedBarcode: undefined,
      confirmedBarcode: !!this.value ? this.value.number : undefined,
      serviceDiscounts: !!this.value ? this.value.serviceDiscounts : undefined,
      productsDiscounts: !!this.value
        ? this.value.productsDiscounts
        : undefined,
      remaining: !!this.value ? this.value.remaining : undefined,
      customerCardId: !!this.value ? this.value.id : undefined,
      customerId: !!this.value ? this.value.customerId : undefined,
      ghost: !!this.value ? this.value.ghost : undefined,
      loadCardDialog: false,
      loadValue: undefined,
      desksStatus: undefined,
      showErrorDialog: false
    };
  },
  mounted: function() {},
  props: {
    value: {},
    scanning: {
      type: Boolean,
    },
    showLoadButton: {
      type: Boolean,
      default: false,
    },
    customer: {},
    sendSocket: {
      default: false
    }
  },
  methods: {
    handleConfirmBarcode() {
      let confirmed = confirm(
        "Vuoi attivare la carta con il codice " + this.insertedBarcode + "?"
      );

      if (confirmed) {
        this.setCardNumber();
        this.activateCardDialog = false;
      }
    },
    setCardNumber() {
      this.confirmedBarcode = this.insertedBarcode;
      if (!!this.customerId) {
        customerCardsService
          .activate(
            {
              id: this.customerId,
            },
            {
              number: this.confirmedBarcode,
            }
          )
          .then((results) => {
            this.customerCardId = results.id;
            this.$emit("input", { ...this.sharedCustomerCard });
          })
          .catch((err) => {
            console.log(err);
            this.$delegates.snackbar("Impossibile attivare la carta");
          });
      } else {
        this.$emit("input", { ...this.sharedCustomerCard });
      }
    },
    deactivateCard() {
      if (!!this.customerCardId) {
        let confirmed = confirm("Vuoi davvero disattivare la tessera?");

        if (confirmed) {
          customerCardsService
            .deactivate({ id: this.customerCardId })
            .then(() => {
              this.confirmedBarcode = undefined;
              this.insertedBarcode = undefined;
              this.$emit("input", undefined);
            })
            .catch((err) => {
              this.$delegates.snackbar("Impossibile disattivare la carta");
            });
        }
      } else {
        this.$emit("input", undefined);
      }
    },
    updateProductDiscount() {
      if (!!this.customerCardId) {
        if(this.productsDiscounts > 100) {
          this.productsDiscounts = 100
        }
        if(this.productsDiscounts < 0 || !this.productsDiscounts) {
          this.productsDiscounts = 0
        }

        customerCardsService
          .update({
            id: this.customerCardId,
            productsDiscounts: this.productsDiscounts,
            sendSocket: this.sendSocket,
          })
          .then(() => {
            this.$emit("input", { ...this.sharedCustomerCard });
          })
          .catch((err) => {
            console.log(err);
            this.$delegates.snackbar("Impossibile modificare la carta");
          });
      } else {
        this.$emit("input", { ...this.sharedCustomerCard });
      }
    },
    updateServiceDiscount() {
      if (!!this.customerCardId) {
        if(this.serviceDiscounts > 100) {
          this.serviceDiscounts = 100
        }
        if(this.serviceDiscounts < 0 || !this.serviceDiscounts) {
          this.serviceDiscounts = 0
        }

        customerCardsService
          .update({
            id: this.customerCardId,
            serviceDiscounts: this.serviceDiscounts,
            sendSocket: this.sendSocket,
          })
          .then(() => {
            this.$emit("input", { ...this.sharedCustomerCard });
          })
          .catch((err) => {
            this.$delegates.snackbar("Impossibile modificare la carta");
          });
      } else {
        this.$emit("input", { ...this.sharedCustomerCard });
      }
    },
    confirmLoad() {
      this.$emit("confirm-load", this.loadValue);
      this.loadCardDialog = false;
    },
    charginDialog() {
      cashDeskService.getOpened().then((results) => {
        this.desksStatus = results;
        if(this.desksStatus != undefined)
        {
          this.loadCardDialog = true;
        } else {
          this.showErrorDialog = true;
        }
      })
    }
  },
  computed: {
    sharedCustomerCard() {
      return {
        id: this.customerCardId,
        number: this.confirmedBarcode,
        serviceDiscounts: this.serviceDiscounts || 0,
        productsDiscounts: this.productsDiscounts || 0,
        remaining: this.remaining,
        customerId: this.customerId,
        ghost: this.ghost
      };
    },
    cardActive() {
      return !!this.confirmedBarcode;
    },
    localScanning() {
      return this.activateCardDialog;
    },
  },
  watch: {
    localScanning(newVal) {
      this.$emit("update:scanning", newVal);
    },
    value(newVal) {
      (this.confirmedBarcode = !!newVal ? newVal.number : undefined),
        (this.serviceDiscounts = !!newVal
          ? newVal.serviceDiscounts
          : 0),
        (this.productsDiscounts = !!newVal
          ? newVal.productsDiscounts
          : 0),
        (this.remaining = !!newVal ? newVal.remaining : undefined),
        (this.customerCardId = !!newVal ? newVal.id : undefined),
        (this.customerId = !!newVal ? newVal.customerId : undefined);
    },
  },
  barcode: {
    scan(barcode) {
      if (this.activateCardDialog) {
        this.insertedBarcode = barcode;
        this.setCardNumber();
        this.activateCardDialog = false;
      }
    },
  },
};
</script>

<style>
.back-color-gradient {
  background-image: linear-gradient(#8a77e2, #50bfe1);
}
</style>
