import eventsHandler from '@/services/common/eventsHandler.js'

class CustomerForm extends eventsHandler {
  constructor() {
    super()
    this._customer = {}
    this._valid = false
  }

  get customer() {
    const customer = {
      ...this._customer
    }
    return customer
  }

  get valid() {
    return this._valid
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetCustomer() {
    this._customer = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {customer: {}})
  }

  updateField(key, value) {
    this._customer[key] = value
    this._executeCallbacksOf('update', {customer: this._customer})
    this._executeCallbacksOf('update-' + key, {key: this._customer[key]})
  }

  updateObjectField(key, objectkey, value) {
    this._customer[key][objectkey] = value
    this._executeCallbacksOf('update', {customer: this._customer})
    this._executeCallbacksOf('update-' + key, {key: this._customer[key]})
  }

  updateObject(customer) {
    this._customer = customer
    this._executeCallbacksOf('update', {customer: this._customer})
  }
}

export default new CustomerForm()