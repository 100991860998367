<template>
  <div>
    <v-autocomplete
      v-model="selectedColorLines"
      :items="colorLines"
      item-text="name"
      :item-value="itemValue"
      filled
      :label="label"
      :hide-details="hideDetails"
      :placeholder="placeholder"
      :dense="dense"
      :multiple="multiple"
      :clearable="clearable"
      :loading="loading"
      :return-object="returnObject"
      :prepend-icon="prependIcon"
      :rules="rules"
      :disabled="disabled"
      :solo-inverted="soloInverted"
    >
      <template slot="append-outer">
        <slot name="append-outer"></slot>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import colorService from "@/services/colorLines/colorLines.service.js";
import Vue from 'vue'

export default {
  name: "ColorLinesAutocomplete",
  data: function() {
    let selection;
    if(!!this.value) {
      if(this.multiple && !this.returnObject) {
        selection = []
        for(let i = 0; i < this.value.length; i++) {
          selection.push(this.value[i])
        }
      } else if(!this.returnObject) {
        selection = this.value
      } else {
        selection = this.value
      }
    }

    return {
      colorLines: [],
      selectedColorLines: selection,
      loading: false
    }
  },
  props: {
    value: {
      default: function() {
        return []
      }
    },
    bus: {
      type: Object,
      default: () => { new Vue() }
    },
    placeholder: {
      type: String,
      default: 'Tutti i Tipi Colorazione'
    },
    dense: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    label: {
      type: String,
      default: 'Tipo Colorazione'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      default: 'auto'
    },
    prependIcon: {
      type: String,
      default: 'mdi-invert-colors'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    soloInverted: {
      type: Boolean,
      default: true
    },
    filters: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  mounted: function() {
    this.loadColorLines()

    if(!!this.bus) {
      this.bus.$on('reload', () => {
        this.loadColorLines()
      })
    }
  },
  methods: {
    loadColorLines() {
      this.loading = true
      colorService.cachedList(this.filters).then((colorLines) => {
        this.colorLines = colorLines
        this.loading = false
      })
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }
  },
  watch: {
    value(newVal) {
      this.selectedColorLines = newVal
    },
    selectedColorLines(newVal) {
      if(this.multiple && !this.arraysEqual(newVal, this.value)) {
        this.$emit('input', newVal)
      } else if(!this.multiple && newVal != this.value) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style>

</style>