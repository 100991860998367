<template>
  <v-row>
    <v-col cols="4">
      <CustomerCardForm
        class="mt-5"
        v-model="customerCard"
        @input="handleChangeCard"
        :send-socket="true"
      ></CustomerCardForm>
    </v-col>
    <v-col v-if="canViewGhost" cols="8">
      <v-subheader class="text-h6 py-1 mt-2 mb-2">Storico Pagamenti Tessera</v-subheader>
      <CardHistoryFormTab>
      </CardHistoryFormTab> 
    </v-col>
  </v-row> 
</template>

<script>
import customerForm from '@/services/customers/customer.form.js'
import CustomerCardForm from '../customerCard/CustomerCardForm.vue'
import CardHistoryFormTab from './CardHistoryFormTab.vue'

export default {
  name: "CardFormTab",
  components: {
    CustomerCardForm,
    CardHistoryFormTab
  },
  props: {
    canViewGhost: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      customerCard: undefined
    }
  },
  mounted: function() {
    let self = this
    this.customerCard = !!customerForm.customer.customerCard ? customerForm.customer.customerCard : {customerId: customerForm.customer.id }

    customerForm.on('update', function() {
      if(customerForm.customer.customerCard) {
        self.customerCard = customerForm.customer.customerCard
      } else {
        self.customerCard = {
          customerId: customerForm.customer.id
        }
      }
    })
  },
  methods: {
    handleChangeCard() {
      customerForm.updateField('customerCard', {...this.customerCard})  
    },
  }
}
</script>

<style>

</style>