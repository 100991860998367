<template>
  <div>
    <div v-for="(item, indexInfoTab) in infoTabs" :key="item.key">
      <div
        style="color: red; font-size: 20px"
      >{{item.title}}
      </div>
      <div class="d-flex">
        <div>
          <div v-for="(element, indexInfoTabElement) in item.items" :key="element.key">
            <OpenQuestion
              v-if="element.type == 'openQuestion'"
              :question="element.question"
              :value="element.value"
              :label="element.label"
              @valueChanged="($event) => {
                infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event.value
                updateCustomerForm()
              }"
            ></OpenQuestion>  
            <CheckBoxQuestion
              v-else-if="element.type == 'checkbox'"
              :value="element.value"
              :question="element.question"
              :checkboxes="element.checkboxes"
              :textfield="element.textfield"
              @checkboxClicked="($event) => {
                infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event.value
                updateCustomerForm()
              }"
              @textfieldChanged="($event) => {
                infoTabs[indexInfoTab].items[indexInfoTabElement].textfield = $event
                updateCustomerForm()
              }"
            ></CheckBoxQuestion>  
            <TableQuestion
              v-else-if="element.type == 'table'"
              :items="element.value"
            ></TableQuestion>  
          </div>
        </div>
        <table v-if="infoTabs.length > 0 && indexInfoTab == 0" style="width: 40%; margin-left:100px; height:40%; margin-top:50px" >
          <tr>
            <td  style="width: 50%">
              <v-img
                :src="imagePath"
                contain
                gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0.2)"
                max-width="20vw"
                max-height="27vh"
              > 
              </v-img>
            </td>  
            <td>
              <v-textarea
                auto-grow
                label="Note"
                v-model="infoTabs[0].textField"
                @input="updateCustomerForm"
                color="grey"
                rows="9"
                hide-details="auto"
              ></v-textarea>  
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import formsDataSheet from './FormsDataSheetJson.js'
import customerForm from "@/services/customers/customer.form.js";
import OpenQuestion from "./components/OpenQuestion.vue";
import CheckBoxQuestion from "./components/CheckBoxQuestion.vue";
import TableQuestion from "./components/TableQuestion.vue";

export default {
  name: 'BeautyFaceCheckupDataSheet',
  components: {
    OpenQuestion,
    CheckBoxQuestion,
    TableQuestion,
  },
  data: function() {
    return {
      newForm: new formsDataSheet(),
      infoTabs: undefined,
      loading: false,
      imagePath: !!customerForm.customer && 
          !!customerForm.customer.gender && 
          customerForm.customer.gender == 'male' ? 
          require('@/assets/manFace.png') : 
          require('@/assets/womanFace.png'),
    }
  },
  props: {
    completed:{
      type: Boolean,
      default: false,
    },
  },
  mounted: function() {
    customerForm.on('update', function() {
      if(!!customerForm.customer && !!customerForm.customer.gender) {
        this.imagePath = !!customerForm.customer && 
          !!customerForm.customer.gender && 
          customerForm.customer.gender == 'male' ? 
          require('@/assets/manFace.png') : 
          require('@/assets/womanFace.png')
      }
    }.bind(this))
    if(!customerForm.customer.beautyDataSheet){
      customerForm.updateField("beautyDataSheet", this.newForm.beautyForm);
      this.infoTabs = [...this.newForm.beautyForm.beautyFaceCheckup]
    }
    else if(!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyFaceCheckup){
      this.infoTabs = !!customerForm.customer.beautyDataSheet.beautyFaceCheckup.data ? [...customerForm.customer.beautyDataSheet.beautyFaceCheckup.data] : [...customerForm.customer.beautyDataSheet.beautyFaceCheckup]
    }

    this.updateCustomerForm()
  },
  methods: {
    /**
     * Update customer form Object
     */
    updateCustomerForm() {
      if (!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyFaceCheckup) {
        customerForm.updateObjectField("beautyDataSheet","beautyFaceCheckup", this.infoTabs);
      } else if(!!customerForm.customer.beautyDataSheet) {
        // Creation case
        customerForm.updateObjectField("beautyDataSheet","beautyFaceCheckup", this.infoTabs);
      }
      else {
        console.log('Error in updateLocalField');
      }
      this.checkAllCompleted()
    },
    /**
     * Check if all the fields are completed
     */
    checkAllCompleted(){
      let completed = false
      completed = this.infoTabs.some((tab) => {
        if (!!tab.textField)
          return (tab.textField !== null && tab.textField !== undefined && tab.textField !== "")

        return tab.items.some((item) => {
          if (!!item.checkboxes) {
            return (item.value !== null && item.value !== undefined && item.value !== "") ||
              (item.textfield !== null && item.textfield !== undefined && item.textfield !== "")
          }
          if (Array.isArray(item.value))
            return item.value.some(el => (el.value !== null && el.value !== undefined && el.value !== ""))
          return item.value !== null && item.value !== undefined && item.value !== ""
        })
      })
      this.$emit('update:completed', completed)
    },
  },
  watch: {
  }
}
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
}
</style>