<template>
  <div>
    <table :style="cssProps">
      <tr>
        <td style="width: 70%">
        <v-img
          :src="imagePath"
          contain
          gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0.2)"
          max-height="80vh"
          position="15%"
        >
          <div class="d-flex pa-1" v-for="(item, i) in localcmMeasures" :key="i">
            <v-spacer style="width: 10vw"></v-spacer>
            <v-text-field 
              v-if="!(imagePath == require('@/assets/bodyMale.png') && i > 5)"
              v-model="localcmMeasures[i]"
              :height="'70'"
              filled 
              dense 
              hide-details="auto" 
              color="grey" 
              :label="String(i + 1)"
              @input="emitNewValue('cmMeasures',localcmMeasures)"
              :disabled="disabled"
            ></v-text-field>
          </div>  
        </v-img>
        </td>
        <td class="d-flex flex-column align-top">
          <v-text-field 
            v-model="localArms"
            :height="'70'"
            filled 
            dense 
            hide-details="auto" 
            color="grey" 
            class="pa-1"
            label="Braccia"
            @input="emitNewValue('arms',localArms)"
            :disabled="disabled"
          ></v-text-field>
          <v-text-field 
            class="pa-1"
            v-model="localChest"
            :height="'70'"
            filled 
            dense 
            hide-details="auto" 
            color="grey" 
            label="Torace"
            @input="emitNewValue('chest',localChest)"
            :disabled="disabled"
          ></v-text-field>
          <v-text-field 
            class="pa-1"
            v-model="localWaist"
            :height="'70'"
            filled 
            dense 
            hide-details="auto" 
            color="grey" 
            label="Vita"
            @input="emitNewValue('waist',localWaist)"
            :disabled="disabled"
          ></v-text-field>  
          <v-text-field 
            class="pa-1"
            v-model="localWrist"
            :height="'70'"
            filled 
            dense 
            hide-details="auto" 
            color="grey" 
            label="Polso"
            @input="emitNewValue('wrist',localWrist)"
            :disabled="disabled"
          ></v-text-field>  
          <v-text-field 
            class="pa-1"
            v-model="localFlanks"
            :height="'70'"
            filled 
            dense 
            hide-details="auto" 
            color="grey" 
            label="Fianchi"
            @input="emitNewValue('flanks',localFlanks)"
            :disabled="disabled"
          ></v-text-field>   
          <v-text-field 
            class="pa-1"
            v-model="localThigh"
            :height="'70'"
            filled 
            dense 
            hide-details="auto" 
            color="grey" 
            label="Coscia"
            @input="emitNewValue('thigh',localThigh)"
            :disabled="disabled"
          ></v-text-field>   
          <v-text-field 
            class="pa-1"
            v-model="localKnee"
            :height="'70'"
            filled 
            dense 
            hide-details="auto" 
            color="grey" 
            label="Ginocchio"
            @input="emitNewValue('knee',localKnee)"
            :disabled="disabled"
          ></v-text-field>   
          <v-text-field 
            class="pa-1"
            v-model="localCalf"
            :height="'70'"
            filled 
            dense 
            hide-details="auto" 
            color="grey" 
            label="Polpaccio"
            @input="emitNewValue('calf',localCalf)"
            :disabled="disabled"
          ></v-text-field>   
          <v-text-field 
            class="pa-1"
            v-model="localAnkle"
            :height="'70'"
            filled 
            dense 
            hide-details="auto" 
            color="grey" 
            label="Caviglia"
            @input="emitNewValue('ankle',localAnkle)"
            :disabled="disabled"
          ></v-text-field>  
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import customerForm from "@/services/customers/customer.form.js";

export default {
  name: "TableBeautyBodyImage",
  components: {
    TypeDataTable,
  },
  data: function () {
    return {
      localcmMeasures: this.cmMeasures,
      localArms: this.arms,
      localChest: this.chest,
      localWaist: this.waist,
      localWrist: this.wrist,
      localFlanks: this.flanks,
      localThigh: this.thigh,
      localKnee: this.knee,
      localCalf: this.calf,
      localAnkle: this.ankle,
      imagePath: !!customerForm.customer && 
        !!customerForm.customer.gender && 
        customerForm.customer.gender == 'male' ? 
        require('@/assets/bodyMale.png') : 
        require('@/assets/bodyWoman.png'),
    };
  },
  props: {
    title:{
      type: String,
      default: 'MISURE CORPO'
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cmMeasures:{
      type: Array,
      default: ['','','','','','','','','']
    },
    arms:{
      type: String,
      default: ''
    },
    chest:{
      type: String,
      default: ''
    },
    waist:{
      type: String,
      default: ''
    },
    wrist:{
      type: String,
      default: ''
    },
    flanks:{
      type: String,
      default: ''
    },
    thigh:{
      type: String,
      default: ''
    },
    knee:{
      type: String,
      default: ''
    },
    calf:{
      type: String,
      default: ''
    },
    ankle:{
      type: String,
      default: ''
    },
  },
  mounted: function () {
    customerForm.on('update', function() {
      if(!!customerForm.customer && !!customerForm.customer.gender) {
        this.imagePath = !!customerForm.customer && 
          !!customerForm.customer.gender && 
          customerForm.customer.gender == 'male' ? 
          require('@/assets/bodyMale.png') : 
          require('@/assets/bodyWoman.png')
      }
    }.bind(this))
  },
  methods: {
    /**
     * @description Emit the event to update the data
     * @param {string} key
     * @param {any} value
     */
    emitNewValue(key, value) {
      this.$emit(key, value);
    },
  },
  watch: {
    chest(newVal){
      this.localChest = newVal;
    },
    waist(newVal){
      this.localWaist = newVal;
    },
    wrist(newVal){
      this.localWrist = newVal;
    },
    flanks(newVal){
      this.localFlanks = newVal;
    },
    thigh(newVal){
      this.localThigh = newVal;
    },
    knee(newVal){
      this.localKnee = newVal;
    },
    calf(newVal){
      this.localCalf = newVal;
    },
    ankle(newVal){
      this.localAnkle = newVal;
    },
    cmMeasures(newVal){
      this.localcmMeasures = newVal;
    },
  },
  computed: {
  cssProps() {
    return {
      '--headers-color': this.color,
    }
  }
  }
};
</script>


<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}

td {
  text-align: left;
}
th {
  text-align: left;
  padding: 8px;
  background-color: var(--headers-color) !important;
}
tr {
  border-bottom: 1px solid #dddddd;
}
.hoverColor:hover {
  background-color: #f0efef;
}

</style>