<template>
  <v-form v-model="valid">
    <v-row class="mt-2">
      <v-col cols="4">
        <OperatorsAutocomplete
          v-if="!setOperator"
          v-model="localValue.operatorId"
          item-value="id"
          :multiple="false"
          label="Operatore"
          @input="emitValueChange"
        ></OperatorsAutocomplete>
        <OperatorsAutocomplete
          v-else
          v-model="localValue.operator"
          return-object
          :multiple="false"
          label="Operatore"
          @input="emitValueChange"
        ></OperatorsAutocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ComboAdder
          v-model="localValue.treatmentType" 
          :creator="storeNewTreatmentType" 
          :fetcher="fetchTreatmentTypes" 
          item-text="name" 
          item-value="id"
          return-object
          v-if="setExternalTable"
          :list-name="$translations.t('models.DataSheet.fields.treatmentTypeId')"
          @input="emitValueChange"
        ></ComboAdder>
        <ComboAdder
          v-else
          v-model="localValue.treatmentTypeId" 
          :creator="storeNewTreatmentType" 
          :fetcher="fetchTreatmentTypes" 
          item-text="name" 
          item-value="id"
          :list-name="$translations.t('models.DataSheet.fields.treatmentTypeId')"
          @input="emitValueChange"
        ></ComboAdder> 
      </v-col>
      <v-col>
        <ComboAdder
          v-if="setExternalTable"
          v-model="localValue.needleChoice" 
          :creator="storeNewNeedleChoice" 
          :fetcher="fetchNeedleChoices" 
          item-text="name"
          item-value="id"
          return-object
          :list-name="$translations.t('models.DataSheet.fields.needleChoiceId')"
          @input="emitValueChange"
        ></ComboAdder> 
        <ComboAdder
          v-else
          v-model="localValue.needleChoiceId" 
          :creator="storeNewNeedleChoice" 
          :fetcher="fetchNeedleChoices" 
          item-text="name"
          item-value="id"
          :list-name="$translations.t('models.DataSheet.fields.needleChoiceId')"
          @input="emitValueChange"
        ></ComboAdder> 
      </v-col>
      <v-col>
        <v-text-field
          v-model="localValue.number"
          :label="$translations.t('models.DataSheet.fields.number')"
          filled
          dense
          hide-details="auto"
          @input="emitValueChange"
        ></v-text-field>
      </v-col>
      <v-col>
        <ComboAdder
          v-if="setExternalTable"
          v-model="localValue.colorChoice" 
          :creator="storeNewColorChoice" 
          :fetcher="fetchColorChoices" 
          item-text="name"
          item-value="id"
          return-object
          :list-name="$translations.t('models.DataSheet.fields.colorChoiceId')"
          @input="emitValueChange"
        ></ComboAdder>
        <ComboAdder
          v-else
          v-model="localValue.colorChoiceId" 
          :creator="storeNewColorChoice" 
          :fetcher="fetchColorChoices" 
          item-text="name"
          item-value="id"
          :list-name="$translations.t('models.DataSheet.fields.colorChoiceId')"
          @input="emitValueChange"
        ></ComboAdder> 
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ComboAdder
          v-if="setExternalTable"
          v-model="localValue.pmuMethod"
          :creator="storeNewPmuMethod" 
          :fetcher="fetchPmuMethods" 
          item-text="name"
          item-value="id"
          return-object
          :list-name="$translations.t('models.DataSheet.fields.pmuMethodId')"
          @input="emitValueChange"
        ></ComboAdder> 
        <ComboAdder
          v-else
          v-model="localValue.pmuMethodId" 
          :creator="storeNewPmuMethod" 
          :fetcher="fetchPmuMethods" 
          item-text="name"
          item-value="id"
          :list-name="$translations.t('models.DataSheet.fields.pmuMethodId')"
          @input="emitValueChange"
        ></ComboAdder> 
      </v-col>
      <v-col>
        <v-text-field
          v-model="localValue.additionalDescription"
          :label="$translations.t('models.DataSheet.fields.additionalDescription')"
          filled
          dense
          hide-details="auto"
          @input="emitValueChange"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="localValue.sessionType"
          :label="$translations.t('models.DataSheet.fields.sessionType')"
          filled
          dense
          hide-details="auto"
          :items="[
            { text: 'Prima Seduta', value: 'firstMeeting' },
            { text: 'Ritocco', value: 'touchUp' }
          ]"
          :menu-props="{
            offsetY: true
          }"
          @input="emitValueChange"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-model="localValue.notes"
          :label="$translations.t('models.DataSheet.fields.notes')"
          filled
          dense
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ComboAdder from '@/components/common/ComboAdder.vue'
import { DateTimePicker } from "likablehair-ui-components"
import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete.vue'
import treatmentTypesService from '@/services/treatmentTypes/treatmentTypes.service.js'
import needleChoicesService from '@/services/needleChoices/needleChoices.service.js'
import colorChoicesService from '@/services/colorChoices/colorChoices.service.js'
import pmuMethodsService from '@/services/pmuMethods/pmuMethods.service.js'

export default {
  name: "PmuDataSheetForm",
  components: {
    DateTimePicker,
    OperatorsAutocomplete,
    ComboAdder
  },
  data: function() {
    const value = {...this.value}
    value['treatmentTypeId'] = !!value['treatmentTypeId'] ? parseInt(value['treatmentTypeId']) : undefined
    value['needleChoiceId'] = !!value['needleChoiceId'] ? parseInt(value['needleChoiceId']) : undefined
    value['colorChoiceId'] = !!value['colorChoiceId'] ? parseInt(value['colorChoiceId']) : undefined
    value['pmuMethodId'] = !!value['pmuMethodId'] ? parseInt(value['pmuMethodId']) : undefined

    return {
      localValue: value,
      valid: false
    }
  },
  props: {
    value: { },
    setOperator: {
      type: Boolean,
      default: false
    },
    setExternalTable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitValueChange() {
      this.$emit('input', this.localValue)
    },
    storeNewTreatmentType(treatmentType) {
      return new Promise((resolve, reject) => {
        treatmentTypesService.create(treatmentType).then((result) => {
          resolve(result)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    fetchTreatmentTypes() {
      return new Promise((resolve, reject) => {
        treatmentTypesService.list().then((result) => {
          resolve(result.rows)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    storeNewNeedleChoice(needleChoice) {
      return new Promise((resolve, reject) => {
        needleChoicesService.create(needleChoice).then((result) => {
          resolve(result)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    fetchNeedleChoices() {
      return new Promise((resolve, reject) => {
        needleChoicesService.list().then((result) => {
          resolve(result.rows)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    storeNewColorChoice(colorChoice) {
      return new Promise((resolve, reject) => {
        colorChoicesService.create(colorChoice).then((result) => {
          resolve(result)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    fetchColorChoices() {
      return new Promise((resolve, reject) => {
        colorChoicesService.list().then((result) => {
          resolve(result.rows)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    storeNewPmuMethod(pmuMethod) {
      return new Promise((resolve, reject) => {
        pmuMethodsService.create(pmuMethod).then((result) => {
          resolve(result)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    fetchPmuMethods() {
      return new Promise((resolve, reject) => {
        pmuMethodsService.list().then((result) => {
          resolve(result.rows)
        }).catch((error) => {
          reject(error)
        })
      })
    },
  },
  watch: {
    value(newValue) {
      newValue['treatmentTypeId'] = !!newValue['treatmentTypeId'] ? parseInt(newValue['treatmentTypeId']) : undefined
      newValue['needleChoiceId'] = !!newValue['needleChoiceId'] ? parseInt(newValue['needleChoiceId']) : undefined
      newValue['colorChoiceId'] = !!newValue['colorChoiceId'] ? parseInt(newValue['colorChoiceId']) : undefined
      newValue['pmuMethodId'] = !!newValue['pmuMethodId'] ? parseInt(newValue['pmuMethodId']) : undefined
      this.localValue = newValue
    }
  }
}
</script>

<style>

</style>