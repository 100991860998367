<template>
  <div style="height: 70vh" ref="dataTableWrapper" class="mb-10">
    <TypeDataTable
      :headers="localHeaders"
      :items="localItems"
      :showSelect="false"
      :showActions="false"
      :loading="loading"
      :page.sync="page"
      :row-per-page.sync="rowPerPage"
      :total-pages.sync="totalPages"
      :height="dataTableHeight"
    >
      <template v-slot:custom-message="{ item }">
        {{item.message}}
      </template>
      <template v-slot:custom-smsProviderId="{ item }">
        <v-icon>{{item.smsProviderId == 1 ? 'mdi-message-fast' :  'mdi-whatsapp'}}</v-icon>
      </template>
      <template v-slot:custom-createdAt="{ item }">
        {{item.createdAt}}
      </template>
      <template v-slot:custom-status="{ item }">
        {{item.status}}
      </template>
    </TypeDataTable>
  </div>
</template>

<script>
import customerForm from "@/services/customers/customer.form.js";
import smsService from '@/services/sms/sms.service.js';
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";

export default {
  name: "DiaryFormTab",
  components: {
    TypeDataTable,
    DateTimeFormattor,
  },
  data: function () {
    return {
      dataTableHeightValue: 400,
      resizeListener: undefined,
      localHeaders: [
        { text: "Messaggio", value: "message", type: "custom" },
        {
          text: "Provider SMS",
          value: "smsProviderId",
          type: "custom",
        },
        { text: "Data Invio", value: "createdAt", type: "datetime" },
        { text: "Stato SMS", value: "status", type: "custom" },
      ],
      localItems: [],
      selectedCustomerId: undefined,
      loading: true,
      page: 1,
      rowPerPage: 200,
      totalPages: 0,
    };
  },
  mounted: function () {
    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight * 0.99;

    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight * 0.99;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);

    this.updateCustomerId();
    customerForm.on("update", this.updateCustomerId);
    this.$once("hook:beforeDestroy", function () {
      customerForm.off("update", this.updateCustomerId);
    });
  },
  methods: {
    updateCustomerId() {
      if (
        this.selectedCustomerId != customerForm.customer.id &&
        !!customerForm.customer.id
      ) {
        this.loading = true
        this.selectedCustomerId = customerForm.customer.id;
        this.fetchRegister().then((result) => {
          this.localItems = result;
          this.loading = false
        });
      }
    },
    fetchRegister() {
      return new Promise((resolve, reject) => {
        let filters = [{ field: "customerId", operator: "equal", value: this.selectedCustomerId }]

        smsService.list(this.page, this.rowPerPage, filters).then((result) => {
          if (this.rowPerPage != result.rowPerPage) {
            this.rowPerPage = result.length;
          }

          if (this.page != result.page) {
            this.page = result.page;
          }

          this.totalPages = result.totalPages;
          if (this.totalPages < this.page && this.totalPages != 0) {
            this.page = this.totalPages;
          }
          this.loading = false;

          resolve(result.rows)
        });
      });
    },
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  }
};
</script>

<style>
</style>