<template>
  <v-form v-model="valid">
    <v-row class="mt-2">
      <v-col cols="4">
        <OperatorsAutocomplete
          v-if="!setOperator"
          v-model="localValue.operatorId"
          item-value="id"
          :multiple="false"
          label="Operatore"
          @input="emitValueChange"
        ></OperatorsAutocomplete>
        <OperatorsAutocomplete
          v-else
          v-model="localValue.operator"
          return-object
          :multiple="false"
          label="Operatore"
          @input="emitValueChange"
        ></OperatorsAutocomplete>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-4">
      <v-col class="py-0 pl-0">
        <v-textarea
          class="green-background"
          v-model="localValue.skinTest"
          filled
          :label="$translations.t('models.DataSheet.fields.skinTest')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="py-0 pl-0">
        <v-textarea
          class="blue-background"
          v-model="localValue.recommendedProducts"
          filled
          :label="$translations.t('models.DataSheet.fields.recommendedProducts')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="pa-0">
        <v-textarea
          class="purple-background"
          v-model="localValue.faceTreatments"
          filled
          :label="$translations.t('models.DataSheet.fields.faceTreatments')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-4">
      <v-col class="py-0 pl-0">
        <v-textarea
          class="pink-background"
          v-model="localValue.bodyTreatments"
          filled
          :label="$translations.t('models.DataSheet.fields.bodyTreatments')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="py-0 pl-0">
        <v-textarea
          class="brown-background"
          v-model="localValue.epilation"
          filled
          :label="$translations.t('models.DataSheet.fields.epilation')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="pa-0">
        <v-textarea
          class="red-background"
          v-model="localValue.packages"
          filled
          :label="$translations.t('models.DataSheet.fields.packages')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-4">
      <v-col class="py-0 pl-0">
        <v-textarea
          class="yellow-background"
          v-model="localValue.other"
          filled
          :label="$translations.t('models.DataSheet.fields.other')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="pa-0">
        <v-textarea
          class="orange-background"
          v-model="localValue.notes"
          filled
          :label="$translations.t('models.DataSheet.fields.notes')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { DateTimePicker } from "likablehair-ui-components"
import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete.vue'

export default {
  name: "BeuticianDataSheetsForm",
  components: {
    DateTimePicker,
    OperatorsAutocomplete
  },
  data: function() {
    return {
      localValue: {...this.value},
      valid: false
    }
  },
  props: {
    value: { },
    setOperator: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitValueChange() {
      this.$emit('input', this.localValue)
    }
  },
  watch: {
    value(newValue) {
      this.localValue = newValue
    }
  }
}
</script>

<style scoped>
  .green-background > .v-input__control > .v-input__slot {
    background: #22ff000f !important;
  }

  .purple-background > .v-input__control > .v-input__slot {
    background: #9500ff0f !important;
  }

  .pink-background > .v-input__control > .v-input__slot {
    background: #ff00b70f !important;
  }

  .blue-background > .v-input__control > .v-input__slot {
    background: #001eff0f !important;
  }

  .red-background > .v-input__control > .v-input__slot {
    background: #ff00000f !important;
  }

  .yellow-background > .v-input__control > .v-input__slot {
    background: #ffea000f !important;
  }

  .orange-background > .v-input__control > .v-input__slot {
    background: #ff99000f !important;
  }
</style>