<template>
  <div>
    <v-form v-model="formValid" ref="registryFormTab" >
  <!--     <v-row class="pa-2">
        <v-col cols="12" md="4" xl="4" style="padding-bottom: 3px">
          <v-subheader class="font-italic font-weight-light my-2 py-0" style="height: fit-content">Generali</v-subheader>
        </v-col>
        <v-col cols="12" md="4" xl="4" style="margin-top: 34px; padding-bottom: 3px ">
        </v-col>
        <v-col cols="12" md="4" xl="4" class="px-5" style="padding-bottom: 3px">
          <v-subheader class="font-italic font-weight-light my-2 py-0" style="height: fit-content">Contatti</v-subheader>
        </v-col>
      </v-row> -->
  
      
      <v-row class="my-1">
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
        >
          <v-row>
            <v-subheader class="font-italic font-weight-light my-2 py-0" style="height: fit-content">Generali</v-subheader>
          </v-row>
          <v-row class="py-2 no-gutters">
            <v-col class="py-0 pl-1 pr-0">
              <v-text-field
                v-model="lastname"
                :rules="[presenceRule]"
                filled
                dense
                label="Cognome *"
                hide-details="auto"
                autofocus
                rounded
              ></v-text-field>
            </v-col>
            <v-col class="py-0 pl-1 pr-2">
              <v-text-field
                  v-model="firstname"
                  filled
                  dense
                  :rules="[presenceRule]"
                  label="Nome *"
                  hide-details="auto"
                  rounded
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-2 no-gutters"> 
            <v-col 
              cols="6"
              sm="6"
              md="5"
              lg="5"
              xl="5"
              class="pl-1 pr-0"
              :class="{
                'mb-2': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              }"
            >
              <v-text-field
                v-model="address"
                filled
                dense
                label="Indirizzo"
                hide-details="auto"
                prepend-icon="mdi-google-maps"
                rounded
              ></v-text-field>
            </v-col>
            <v-col 
              cols="6"
              sm="6"
              md="2"
              lg="2"
              xl="2"
              class="pl-1 pr-0"
            >
              <v-text-field
                v-model="cap"
                :rules="[max255CharRule]"
                filled
                dense
                label="CAP"
                hide-details="auto"
                rounded
              ></v-text-field>
            </v-col>
            <v-col 
              cols="6"
              sm="6"
              md="2"
              lg="2"
              xl="2"
              class="pl-1 pr-0">
              <v-text-field
                v-model="province"
                :rules="[max255CharRule]"
                filled
                dense
                label="Provincia"
                maxlength="2"
                hide-details="auto"
                rounded
              ></v-text-field>
            </v-col>
            <v-col 
              cols="6"
              sm="6"
              md="3"
              lg="3"
              xl="3"
              class="pl-1 pr-2">
              <v-text-field
                v-model="city"
                :rules="[max255CharRule]"
                filled
                dense
                label="Citta"
                hide-details="auto"
                rounded
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row  class="py-2 no-gutters">
            <v-col 
              cols="6"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              class="pa-0 pt-2"
            >
              <DatePicker
                v-model="birthday" 
                label="Data di nascita" 
                hide-details="auto"
                filled
                rounded
              ></DatePicker>
            </v-col>
            <v-col 
              cols="6"
              sm="6"
              md="3"
              lg="4"
              xl="3"  
              class="pa-0 pt-2 d-flex justify-content"
            >
              <div class="d-flex">
                <v-subheader class="font-italic font-weight-light" >Sesso*</v-subheader>
                <ButtonGroupSelector 
                  :items="genderOptions" 
                  itemValue="name"
                  v-model="gender"
                  class="mb-4"
                  :required="true"
                ></ButtonGroupSelector>  
              </div>
            </v-col>
            <v-col 
              cols="12"
              sm="12"
              md="5"
              lg="4"
              xl="5" 
              class="pa-0 pt-2 pr-2"
            >
              <v-text-field
                v-model="fiscalCode"
                filled
                dense
                label="Codice Fiscale"
                hide-details="auto"
                prepend-icon="mdi-card-account-details"
                :rules ="[fiscalCodeValid]"
                rounded>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
        >
          <v-row>
            <v-subheader class="font-italic font-weight-light my-2 py-0" style="height: fit-content">Contatti</v-subheader>
          </v-row>
          <v-row class="py-2 no-gutters">
            <v-col
              class="py-0 pl-1 pr-0"
            >
              <v-autocomplete
                v-model="prefixCellphone"
                :items="countriesPrefix"
                :item-text="(item) => item.name + ' ' + '('+item.dial_code+')'"
                :rules="[presenceRule]"
                :item-value="(item) => item.dial_code"
                prepend-icon="mdi-earth"
                label="Prefisso*"
                :return-object="false"
                hide-details="auto"
                class="mt-1"
                dense
                filled
                rounded
              ></v-autocomplete>
            </v-col>
            <v-col
              class="py-0 pl-1 pr-2"
            >
              <v-text-field
                v-model="cellphone"
                :rules="[max255CharRule, presenceRule]"
                class="ml-1 mt-1"
                filled
                dense
                prepend-icon="mdi-cellphone-basic"
                label="Cellulare*"
                hide-details="auto"
                rounded
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-2 no-gutters">
            <v-col class="py-0 pl-1 pr-0">
              <v-text-field
                v-model="email"
                filled
                dense
                label="Email"
                prepend-icon="mdi-at"
                hide-details="auto"
                class="mt-3"
                rounded
              ></v-text-field>
            </v-col>
            <v-col class="py-0 pl-1 pr-2">
              <v-text-field
                v-model="telephone"
                :rules="[max255CharRule]"
                filled
                dense
                label="Telefono Fisso"
                prepend-icon="mdi-deskphone"
                hide-details="auto"
                class="ml-1 mt-3"
                rounded
              ></v-text-field>
            </v-col>
          </v-row>
  
          <v-row class="py-2 no-gutters">
            <v-col class="py-0 pl-0 pr-1">
              <v-checkbox
                v-model="sendByWhatsapp"
                label="Invio messaggi da Whatsapp"
                prepend-icon="mdi-whatsapp"
              >
              </v-checkbox>
            </v-col>
            <v-col class="py-0 pl-1">
              <v-checkbox
                v-model="ignoreMarketing"
                label="Non inviare messaggi"
                prepend-icon="mdi-message-bulleted-off"
              >
              </v-checkbox>
            </v-col>
            <v-col class="py-0 pr-2">
              <v-autocomplete
                v-model="jobId" 
                :items="jobItems" 
                item-text="name" 
                item-value="id"
                dense 
                filled
                hide-details="auto"
                label="Professione"
                prepend-icon = "mdi-briefcase"
                class="mt-3 ml-1"
                rounded
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
        
      <v-row no-gutters class="py-2">
        <v-col>
          <v-text-field
            v-model="presentedBy"
            filled
            dense
            rounded
            :rules="[max255CharRule]"
            label="Presentato da"
            hide-details="auto"
            prepend-icon = "mdi-briefcase"
          ></v-text-field>
        </v-col>
        <v-col class="ml-4">
          <InterestsAutocomplete
            v-model="interests"
            :multiple="true"
            :dense="true"
          ></InterestsAutocomplete>  
        </v-col> 
      </v-row>
      <v-row no-gutters class="py-2">
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="4"
          xl="4"
          :class="{
            'mb-2': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          }"
        >
          <OperatorsAutocomplete
            prepend-icon="mdi-palette"
            v-model="preferredColorOperatorId"
            :dense="true"
            label="Operatore Colore Preferito"
          ></OperatorsAutocomplete>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="4"
          xl="4"
          :class="{
            'mb-2': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          }"
        >
          <OperatorsAutocomplete
            prepend-icon="mdi-content-cut"
            class="ml-3"
            v-model="preferredHairCutOperatorId"
            :dense="true"
            label="Operatore Taglio Preferito"
          ></OperatorsAutocomplete>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="4"
          xl="4"
          :class="{
            'mt-2': $vuetify.breakpoint.md
          }"
        >
          <OperatorsAutocomplete
            prepend-icon="mdi-hair-dryer"
            class="ml-2"
            v-model="preferredHairCurlerOperatorId"
            :dense="true"
            label="Operatore Piega Preferito"
          ></OperatorsAutocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2">
        <v-col cols="6" md="3" xl="3">
          <DatePicker v-model="dateFirstSession" label="Data prima seduta" :filled="true" :rounded="true" :disabled="true" />
        </v-col>
        <v-col cols="6" md="6" class="px-2" xl="6">
          <v-textarea
            :value="noteInterests"
            label="Note"
            :rules="[max255CharRule]"
            rows="1"
            row-height="20"
            auto-grow
            filled
            dense
            rounded
            @input="handleNoteInterestsInput"
          ></v-textarea>
        </v-col>
        <v-col 
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
          @click="intermediaryClick"
        >
          <SuppliersAutocomplete
            v-model="intermediaryCompany"
            clearable
            return-object
            :disabled="!canIntermediary"
            prepend-icon="mdi-account-cowboy-hat"
            :auto-detect-new-supplier="false"
            label="Procacciato da..."
          ></SuppliersAutocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                class="back-color-gradient"
                dark
                large
                color="primary"
                @click="toggleAvatarDialog"
                v-show="customerId"
              >
                <v-icon dark>
                  mdi-camera
                </v-icon>
                Foto del cliente
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-img class="rounded-lg" v-show="!!avatar" id="avatar" :width="450" :height="337.5" :src="avatarUrl"></v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <div 
          v-if="customerId && !consentForm"
        >
          <v-row>
            <v-col
              cols="4"
            >
              <v-checkbox
                v-model="checkRule"
                label="Regolamento GDPR"
                color="primary"
                disabled
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkMarketing"
                label="Opzionale Marketing"
                color="primary"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="3"
            >
              <v-checkbox
                v-model="checkThird"
                label="Opzionale Terzi"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkAnalitycs"
                label="Opzionale Analitiche"
                color="primary"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="2"
              >
              <v-btn
                class="ma-10 back-color-gradient"
                dark
                large
                color="primary"
                @click="toggleSignatureDialog"
              >
                <v-icon dark>
                  mdi-draw
                </v-icon>
                Firma del cliente
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div 
          v-else-if="customerId && consentForm"
        >
          <v-btn
            class="my-10 ml-10 mr-3"
            dark
            large
            color="primary"
            @click="handlePrint()"
          >
            <v-icon dark>
              mdi-signature
            </v-icon>
            Stampa GDPR
          </v-btn>
          <v-btn
            class="my-10 mr-10"
            dark
            large
            color="primary"
            @click="deleteSignature"
            :outlined="!canSignature"
          >
            <v-icon dark>
              mdi-delete-forever
            </v-icon>
            Elimina GDPR
          </v-btn>
        </div>
      </v-row>
  
      <StandardDialog v-model="avatarDialog" title="Avatar Cliente" dialog-width="500px" dialog-height="auto">
        <div>
          <div v-show="!uploadedAvatar">
            <div v-show="!cameraLoading" class="ma-0 mt-2">
              <video class="rounded-lg" v-show="!isPhotoTaken && !avatarExists" ref="camera" :width="450" :height="337.5" autoplay></video>
  
              <canvas class="rounded-lg" v-show="isPhotoTaken && !avatarExists" id="photoTaken" ref="canvas" :width="450"
                :height="337.5"></canvas>
              
              <v-img class="rounded-lg mb-2" v-show="avatarExists" id="avatar" :width="450" :height="337.5" :src="avatarUrl"></v-img>
            </div>
  
            <div v-if="!cameraLoading && !errorCamera" class="d-flex justify-center">
              <v-btn color="primary" x-large elevation4 fab @click="takePhoto">
                <a id="downloadPhoto" download="my-photo.jpg"></a>
                <v-icon x-large color="white">{{avatarExists ? 'mdi-trash-can' : 'mdi-camera'}}</v-icon>
              </v-btn>
            </div>
          </div>
  
          <div>
            <div v-show="!!uploadedAvatar" class="ma-0 mt-2">
              <v-img class="rounded-lg mb-2" id="avatar" :width="450" :height="337.5" :src="uploadedAvatarUrl"></v-img>
            </div>
  
            <v-file-input
              label="Carica file"
              accept="image/*"
              v-model="uploadedAvatar"
            ></v-file-input>
          </div>
        </div>
  
        <template v-slot:footer-actions>
          <v-spacer></v-spacer>
            <v-btn
              text
              color="error"
              @click="toggleAvatarDialog"
            >
              Chiudi
            </v-btn>
          <v-btn text color="default" :loading="loadingPhotoUpload" :disabled="!isPhotoTaken && !uploadedAvatar" @click="uploadAvatar">Salva</v-btn>
        </template>
      </StandardDialog>
    </v-form>
    <StandardDialog
      v-model="dialogBarcode"
      :dialog-height="null"
      dialog-max-width="500px"
      :persistent="true"
    >
      <ManualBarcodeInput
        v-model="intermediaryBarcode"
        @confirm="activeIntermediary"
      >
      </ManualBarcodeInput>
    </StandardDialog>
  
    <StandardDialog
      v-model="dialogBarcodeDiscount"
      :dialog-height="null"
      dialog-max-width="500px"
      :persistent="true"
    >
      <ManualBarcodeInput
        v-model="discountBarcode"
        @confirm="activeDiscount"
      >
      </ManualBarcodeInput>
    </StandardDialog>
  
    <StandardDialog
      v-model="dialogBarcodeGDPR"
      :dialog-height="null"
      dialog-max-width="500px"
      :persistent="true"
    >
      <ManualBarcodeInput
        v-model="GDPRBarcode"
        @confirm="activeGDPR"
      >
      </ManualBarcodeInput>
    </StandardDialog>
  
    <StandardDialog
      v-model="signatureDialog"
      :dialog-height="'208px'"
      :dialog-width="'630px'"
      :persistent="true"
    >
      <SignatureCanvas
        :bus="bus"
        :customerId="customerId"
      >
  
      </SignatureCanvas>
  
      <template v-slot:footer-actions>
          <v-spacer></v-spacer>
            <v-btn
              text
              color="error"
              @click="toggleSignatureDialog"
            >
              Chiudi
            </v-btn>
          <v-btn text color="default" @click="saveSignature">Salva</v-btn>
        </template>
    </StandardDialog>
  </div>
  </template>
  
  <script>
  import Vue from "vue";
  import jobService from "@/services/jobs/jobs.service.js";
  import customerInterestService from "@/services/interests/interests.service.js";
  import customerForm from '@/services/customers/customer.form.js'
  //import {Datepicker} from "likablehair-ui-components"
  import DatePicker from '@/components/common/DatePickerCommon.vue'
  import ComboAdder from '@/components/common/ComboAdder.vue'
  import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete.vue'
  import InterestsAutocomplete from '@/components/common/InterestsAutocomplete.vue'
  import ButtonGroupSelector from '@/components/common/ButtonGroupSelector.vue'
  import debounce from "@/mixins/common/debounce.js"
  import countriesService from '@/services/countries/countries.service.js'
  import customersService from '@/services/customers/customers.service.js';
  import imageLoader from '@/services/images.js'
  import StandardDialog from '@/components/common/StandardDialog.vue'
  import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
  import operatorService from '@/services/operators/operators.service.js'
  import SignatureCanvas from './SignatureCanvas.vue'
  import NumericKeyboardInput from "@/components/common/NumericKeyboardInput.vue";
  import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
  
  export default {
    name: "CustomersRegistryNewFormTab",
    components: {
      DatePicker,
      ComboAdder,
      ButtonGroupSelector,
      OperatorsAutocomplete,
      StandardDialog,
      ManualBarcodeInput,
      SignatureCanvas,
      NumericKeyboardInput,
      SuppliersAutocomplete,
      InterestsAutocomplete
  },
    mixins: [debounce],
    data: function() {
      return {
        genderOptions: [{name:'male',icon:'mdi-human-male'},{name:'female',icon:'mdi-human-female'}],
        firstname: undefined,
        lastname: undefined,
        address: undefined,
        cap: undefined,
        city: undefined,
        province: undefined,
        birthday: undefined,
        gender: undefined,
        telephone: undefined,
        cellphone: undefined,
        email: undefined,
        sendByWhatsapp: false,
        ignoreMarketing: false,
        interests: [],
        presentedBy: undefined,
        preferredColorOperatorId: [],
        preferredHairCutOperatorId: [],
        preferredHairCurlerOperatorId: [],
        anniversaryDate: undefined,
        dateFirstSession: undefined,
        sendBySms: undefined,
        formValid: undefined,
        menu: false,
        datepicker: undefined,
        date: undefined,
        jobId: undefined,
        countriesPrefix: undefined,
        fiscalCode: undefined,
        noteInterests: undefined,
        jobItems: undefined,
        prefixCellphone: undefined,
        intermediaryCompany: undefined,
        avatarDialog: false,
        cameraLoading: false,
        isPhotoTaken: false,
        loadingPhotoUpload: false,
        avatarExists: false,
        avatarUrl: undefined,
        errorCamera: false,
        avatar: undefined,
        customerId: undefined,
        canIntermediary: false,
        intermediaryBarcode: undefined,
        dialogBarcode: false,
        signatureDialog:  false,        
        signatureCanvas: null,
        serviceDiscounts: 0,
        canDiscount: false,
        dialogBarcodeDiscount: false,
        discountBarcode: undefined,
        checkRule: true,
        checkMarketing: true,
        checkThird: true,
        checkAnalitycs: true,
        consentForm: false,
        canSignature: false,
        dialogBarcodeGDPR: false,
        GDPRBarcode: undefined,
        uploadedAvatar: undefined,
  
        max255CharRule: v => {
          if (v) {
            return v.length <= 255 || "Al massimo 255 caratteri";
          } else {
            return true;
          }
        },
        presenceRule: v => {
          return !!v || "Il campo è obbligatorio";
        },
        fiscalCodeValid: v => {
          const rgxToCheck = /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/g
          return (rgxToCheck.test(v) || v == undefined || v == '')
        }
      };
    },
    props: {
      bus: {
        type: Object,
        default: function() {
          return new Vue();
        }
      },
      value: {
        type: Date,
      },
      solo: {
        type: Boolean,
        default: false
      },
      filled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: 'Date'
      },
      prependIcon: {
        type: String,
        default: "mdi-calendar"
      },
      appendIcon: {
        type: String,
        default: undefined
      },
      format: {
        type: String,
        default: 'dd/mm/yyyy',
        validator: function (value) {
          return ['dd/mm/yyyy', 'yyyy-mm-dd'].indexOf(value) !== -1
        }
      }
    },
    mounted: function() {
      var self = this
      self.handleObjectChanges(customerForm.customer)
      customerForm.on('update', function(data) {
        self.handleObjectChanges(data.customer)
      })
      this.fetchJobs()
      this.getCountriesPrefixlist()
      this.validate()
      this.bus.$on('validate', () => {
        this.$refs.registryFormTab.validate()
      })
    },
    methods: {
      fields() {
        return [
          'firstname',
          'lastname',
          'address',
          'cap',
          'city',
          'fiscalCode',
          'province',
          'gender',
          'jobId',
          'telephone',
          'prefixCellphone',
          'cellphone',
          'email',
          'sendByWhatsapp',
          'ignoreMarketing',
          'hairTypesId',
          'interests',
          'presentedBy',
          'preferredOperatorId',
          'preferredColorOperatorId',
          'preferredHairCutOperatorId',
          'preferredHairCurlerOperatorId',
          'sendBySms',
          'noteInterests',
          'intermediaryCompany'
        ]
      },
      setField(name, value) {
        customerForm.updateField(name, value)
      },
      handleObjectChanges(customer) {
        const fields = this.fields()
        const newValKeys = Object.keys(customer)
  
        for(let i = 0; i < fields.length; i++) {
          const field = fields[i]
          if(newValKeys.includes(field) && customer[field] != this[field]) {
            this[field] = customer[field]
          } else if(!customer[field]) {
            this[field] = undefined
          }
        }
  
        if(customer.birthday) {
          let birthday = new Date(customer.birthday)
          if(!this.birthday || birthday.getTime() != this.birthday.getTime()) {
            this.birthday = birthday
          }
        }
  
        if(customer.anniversaryDate) {
          let anniversaryDate = new Date(customer.anniversaryDate)
          if(!this.anniversaryDate || anniversaryDate.getTime() != this.anniversaryDate.getTime()) {
            this.anniversaryDate = anniversaryDate
          }
        }
  
        if(customer.dateFirstSession) {
          let dateFirstSession = new Date(customer.dateFirstSession)
          if(!this.dateFirstSession || dateFirstSession.getTime() != this.dateFirstSession.getTime()) {
            this.dateFirstSession = dateFirstSession
          }
        }
  
        if (customer.sendByWhatsapp == undefined) {
          this.sendByWhatsapp = 'true'
        } else
          this.sendByWhatsapp = customer.sendByWhatsapp
  
        if (customer.ignoreMarketing == undefined) {
          this.ignoreMarketing = false
        } else
          this.ignoreMarketing = customer.ignoreMarketing
  
        if(!!customer.avatar){
          this.avatar = customer.avatar
          imageLoader.getImageUrl(this.avatar).then((url) => {
            this.avatarUrl = url
          })
        }
  
        if(!!customer.consentForm){
          this.consentForm = true
        }
  
        this.customerId = customer.id
      },
      fetchJobs(){
        return new Promise((resolve,reject) => {
          jobService
            .cachedList()
            .then((results) => {
              this.jobItems = results
              resolve()
            }).catch((error) => {
              reject(error);
            });
        })  
      },
      getListCustomerInterests(){
        return new Promise((resolve,reject) => {
          customerInterestService
            .list()
            .then((results) => {
              resolve(results.rows)
            }).catch((error) => {
              reject(error);
            })
        }) 
      },
      storeNewCustomerInterest(newitem){
        return customerInterestService.create(newitem)
      },
      getCountriesPrefixlist(){
        countriesService.listPrefixCached().then((results) => {
          this.countriesPrefix = results
          this.prefixCellphone = this.countriesPrefix.find(i => i.dial_code === '+39')
        }).catch((error) => {
          console.log(error)
        })
      },      
      validate () {
          this.$refs.registryFormTab.validate()
      },
      async toggleAvatarDialog() {
        if (this.avatarDialog) {
          this.isPhotoTaken = false
          this.avatarDialog = false
          this.avatarExists = false
        } else if(!!this.avatar){
          this.avatarDialog = true;
          this.avatarExists = true
          this.avatarUrl = await imageLoader.getImageUrl(this.avatar)
        } else {    
          this.avatarDialog = true;
          this.createCameraElement()
        }
      },
      createCameraElement() {
        this.isLoading = true;
  
        const constraints = (window.constraints = {
          audio: false,
          video: true
        });
  
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
            this.errorCamera = false
          })
          .catch(error => {
            this.isLoading = false;
            alert("May the browser didn't support or there is some errors.");
            this.errorCamera = true
          });
      },
      takePhoto() {
        if(this.avatarExists){
          this.deleteAvatar()
          this.createCameraElement()
          this.avatarExists = false
        } else {
          this.isPhotoTaken = !this.isPhotoTaken;
  
          const context = this.$refs.canvas.getContext('2d');
          context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
        }
      },
      deleteAvatar(){
        customersService.deleteAvatar(this.customerId)
      },
      async uploadAvatar() {
        this.loadingPhotoUpload = true
  
        let data = new FormData()
        if(!!this.uploadedAvatar) {
          data.append("avatar", this.uploadedAvatar, 'avatar.png')
        } else {
          const canvas = document.getElementById("photoTaken")
          canvas.download = "AvatarTmp.png"
    
          let imageBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
          data.append("avatar", imageBlob, 'avatar.png')
        }
  
        await customersService.uploadAvatar(this.customerId, data)
        this.loadingPhotoUpload = false
        this.avatar = (await customersService.reloadAvatar(this.customerId))[0].avatar
        await customerForm.updateField('avatar', this.avatar)
        this.toggleAvatarDialog()
      },
      intermediaryClick(){  
        if(!this.canIntermediary){
          this.dialogBarcode = true
        }
      },
      activeIntermediary(){
        operatorService.canPerformOperation(this.intermediaryBarcode, "IntermediaryOperator").then((result) => {
          if (result) {
            this.canIntermediary = true
            this.bus.$emit('focus')
          } else {
            alert("Non hai i permessi per eseguire questa azione!")
          }
        }).catch((err) => {
          console.error(err)
          alert("Badge non riconosciuto")
        })
        this.intermediaryBarcode = undefined
        this.dialogBarcode = false
      },
      toggleSignatureDialog(){
        if(!this.signatureDialog)
          this.signatureDialog = true
        else{
          this.signatureDialog = false
          this.bus.$emit('clearSignature')
        }
      },
      discountClick(){
        if(!this.canDiscount){
          this.dialogBarcodeDiscount = true
        }
      },
      activeDiscount(){
        operatorService.canPerformOperation(this.discountBarcode, "ApplyDiscounts").then((result) => {
          if (result) {
            this.canDiscount = true
          } else {
            alert("Non hai i permessi per eseguire questa azione!")
          }
        }).catch((err) => {
          console.error(err)
          alert("Badge non riconosciuto")
        })
        this.discountBarcode = undefined
        this.dialogBarcodeDiscount = false
      },
      saveSignature(){
        this.bus.$emit('saveSignature', [this.checkRule, this.checkMarketing, this.checkThird, this.checkAnalitycs])
        this.signatureDialog = false
        this.consentForm = true
      },
      handlePrint() {
        customersService
          .getGdpr(this.customerId)
          .then((result) => {
  
          })
          .catch((err) => {});
      },
      async deleteSignature(){
        if(this.canSignature){
          let ctrl = await customersService.deleteSignature(this.customerId)
          if(ctrl.success){
            this.consentForm = false
            this.canSignature = false
          }
        }
        else{
          this.dialogBarcodeGDPR = true
        }
      },
      activeGDPR(){
        operatorService.canPerformOperation(this.GDPRBarcode, "GDPR").then((result) => {
          if (result) {
            this.canSignature = true
          } else {
            alert("Non hai i permessi per eseguire questa azione!")
          }
        }).catch((err) => {
          console.error(err)
          alert("Badge non riconosciuto")
        })
        this.GDPRBarcode = undefined
        this.dialogBarcodeGDPR = false
      },
      handleNoteInterestsInput(params) {
        if(params == undefined || params == null)
          this.noteInterests = " "
        else
          this.noteInterests = params
      }
    },
    watch: {
      indexButtonGender(newVal) {
        this.gender = this.genderOptions[newVal]
      },
      formValid(newVal) {
        customerForm.setValid(newVal)
      },
      firstname(newVal) {     
        newVal = newVal.trim()
        this.firstname = newVal
        customerForm.updateField('firstname', newVal)
      },
      lastname(newVal) {     
        newVal = newVal.trim()
        this.lastname = newVal
        customerForm.updateField('lastname', newVal)
      },
      address(newVal) {
        customerForm.updateField('address', newVal)
      },
      cap(newVal) {
        customerForm.updateField('cap', newVal)
      },
      city(newVal) {
        customerForm.updateField('city', newVal)
      },
      province(newVal) {
        customerForm.updateField('province', newVal)
      },
      birthday(newVal) {
        customerForm.updateField('birthday', newVal)
      },
      gender(newVal) {
        customerForm.updateField('gender', newVal)
      },
      jobId(newVal) {
        customerForm.updateField('jobId', newVal)
      },
      intermediaryCompany(newVal) {
        customerForm.updateField('intermediaryCompany', newVal)
      },
      telephone(newVal) {
        customerForm.updateField('telephone', newVal)
      },
      prefixCellphone(newVal) {
        customerForm.updateField('prefixCellphone', newVal)
      },
      cellphone(newVal) {
        customerForm.updateField('cellphone', newVal)
      },
      email(newVal) {
        customerForm.updateField('email', newVal)
      },
      sendByWhatsapp(newVal) {
        customerForm.updateField('sendByWhatsapp', newVal)
      },
      ignoreMarketing(newVal) {
        customerForm.updateField('ignoreMarketing', newVal)
      },
      interests(newVal) {
        customerForm.updateField('interests', newVal)
      },
      presentedBy(newVal) {
        customerForm.updateField('presentedBy', newVal)
      },
      preferredColorOperatorId(newVal) {
        if(!!newVal)
          customerForm.updateField('preferredColorOperatorId', newVal)
      },
      preferredHairCutOperatorId(newVal) {
        if(!!newVal)
          customerForm.updateField('preferredHairCutOperatorId', newVal)
      },
      preferredHairCurlerOperatorId(newVal) {
        if(!!newVal)
          customerForm.updateField('preferredHairCurlerOperatorId', newVal)
      },
      anniversaryDate(newVal) {
        customerForm.updateField('anniversaryDate', newVal)
      },
      dateFirstSession(newVal) {
        customerForm.updateField('dateFirstSession', newVal)
      },
      fiscalCode(newVal){
        customerForm.updateField('fiscalCode', newVal)
      },
      noteInterests(newVal){
        if(newVal != undefined || newVal != null)
          customerForm.updateField('noteInterests', newVal)
      }
    },
    computed: {
      uploadedAvatarUrl() {
        return !!this.uploadedAvatar ? URL.createObjectURL(this.uploadedAvatar) : undefined
      }
    }
  };
  </script>
  
  <style>
  .back-color-gradient {
    background-image: linear-gradient(#8a77e2, #50bfe1);
  }
  </style>