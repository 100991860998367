<template>
  <div>
    <template v-if="!!value">
      <div>
        <v-icon size="15" class="mr-1">mdi-information</v-icon> 
        <span class="font-weight-bold">Stato: </span> 
        <span>{{translatedStatus}}</span>
      </div>
      <div>
        <v-icon size="15" class="mr-1">{{gainTypeIcon}}</v-icon> 
        <span class="font-weight-bold">Tipo Guadagno: </span> 
        <span>{{translatedGainType}}</span>
      </div>
      <div>
        <v-icon size="15" class="mr-1">{{useTermsIcon}}</v-icon> 
        <span class="font-weight-bold">Termini di utilizzo: </span> 
        <span>{{translatedUseTerms}}</span>
      </div>
      <div>
        <v-icon size="15" class="mr-1">{{itemGainedIcon}}</v-icon> 
        <span class="font-weight-bold">Premio: </span> 
        <span>{{translatedItemGained}}</span>
      </div>
      <div>
      <v-icon size="15" class="mr-1">mdi-chair-rolling</v-icon> 
        <span class="font-weight-bold">Servizi da scontare: </span> <span style="white-space: break-spaces">{{translatedItems}}</span>
      </div>
      <div v-if="translatedGainType == 'A obiettivo'" v-for="serviceTarget in this.value.targetItems">
        <v-icon size="15" class="mr-1">mdi-chair-rolling</v-icon> 
        <span class="font-weight-bold">Servizo da eseguire: </span> <span>{{serviceTarget.name}}</span>
        <span class="font-weight-bold ml-2">Quantità: </span> <span>{{getServiceTargetQuantityString(serviceTarget)}}</span>
      </div>
      <div>
        <div v-if="translatedItemGained == 'Servizi gratuiti'" v-for="serviceGained in this.value.gainedItems">
          <v-icon size="15" class="mr-1">mdi-hair-dryer-outline</v-icon> 
          <span class="font-weight-bold">Servizio guadagnato: </span> <span>{{serviceGained.name}}</span>
          <span class="font-weight-bold ml-2">Quantità: </span> <span>{{serviceGained.quantity ? serviceGained.quantity : 1}}</span>
        </div>
      </div>
      <div>
        <div v-if="value.itemGained == 'discount' && !value.allServicesValid && !value.allItemsValid" v-for="serviceGained in this.value.gainedItems">
          <v-icon size="15" class="mr-1">mdi-hair-dryer-outline</v-icon> 
          <span class="font-weight-bold">
            Servizio guadagnato: 
          </span> 
          <span>
            {{serviceGained.name}}
          </span>
          <span class="font-weight-bold ml-2">
            Quantità: 
          </span> 
          <span>
            {{
              serviceGained.quantity !== undefined && serviceGained.quantity !== null && serviceGained.quantity !== Infinity
                ? serviceGained.quantity : "Illimitato"
            }}
          </span>
        </div>
      </div>
      </template>
  </div>
</template>

<script>
import dateUtils from '@/mixins/common/dateUtils'

export default {
  name: "PromosInfo",
  mixins: [dateUtils],
  data: function() {
    return {
      itemsType:['Tutti i servizi e prodotti', 'Tutti i servizi', 'Tutti i prodotti']
    }
  },
  props: {
    value: {
      type: Object,
    },
    promoCustomer: {
      type: Object,
    },
  },
  methods:{
    getRemain(service){
      let remain = service.quantity !== undefined && service.quantity !== null ? service.quantity : Infinity
      
      if(!!this.promoCustomer)
        for(let promoCustomerItem of this.promoCustomer.promosCustomerItems.filter(el => el.usageProgress != 'target')) {
          if(promoCustomerItem.promosItem.name == service.name) {
            remain -= promoCustomerItem.usedQuantity
          }
        }
      return remain
    },
    getServiceTargetQuantityString(serviceTarget){
      let total = serviceTarget.quantity
      let used = 0
      let promosCustomerItem

      if(!!this.promoCustomer)
        promosCustomerItem = this.promoCustomer.promosCustomerItems.find(promoCustomerItem => promoCustomerItem.promosItem.serviceId == serviceTarget.id)

      if(!!promosCustomerItem)
        used = promosCustomerItem.usedQuantity

      return used + '/' + total
    }
  },
  computed: {
    translatedStatus() {
      const statusMapper = {
        active: 'Attiva dal ',
        activeInDates: 'Attiva dal ',
        notActive: 'Non Attiva'
      }

      if(this.value.status == 'notActive') {
        return statusMapper[this.value.status]
      } else if(this.value.status == 'active') {
        return [statusMapper[this.value.status], this.dateToString('dd/mm/yyyy', new Date(this.value.activeFrom))].join(' ')
      } else {
        return [
          statusMapper[this.value.status], 
          this.dateToString('dd/mm/yyyy', new Date(this.value.activeFrom)),
          'al',
          this.dateToString('dd/mm/yyyy', new Date(this.value.activeTo)),
        ].join(' ')
      }
    },
    translatedGainType() {
      let mapper = {
        target: "A obiettivo",
        simple: "Semplice",
        price: "Costo"
      }
      
      if(this.value.gainType == 'price')
        return mapper[this.value.gainType] + ' (€ ' + this.value.price + ')'

      return mapper[this.value.gainType]
    },
    gainTypeIcon() {
      let mapper = {
        target: "mdi-bullseye-arrow",
        simple: "mdi-tag-outline",
        price: "mdi-tag-text-outline"
      }

      return mapper[this.value.gainType]
    },
    translatedItemGained() {
      let mapper = {
        discount: "Sconto",
        amount: "Rimanente",
        services: "Servizi gratuiti",
        servicesDiscounted: "Servizi scontati"
      }
      if(this.value.itemGained == 'services') {
        return mapper[this.value.itemGained]
      } else if(this.value.itemGained == 'amount') {
        return mapper[this.value.itemGained] + ' (€' + (!this.promoCustomer ? this.value.amount : this.promoCustomer.remaining) + ')'
      } else {
        return mapper[this.value.itemGained] + ' (' + this.value.discount + '%)'
      }
    },
    itemGainedIcon() {
      let mapper = {
        discount: "mdi-label-percent",
        amount: "mdi-cash",
        services: "mdi-chair-rolling"
      }

      return mapper[this.value.itemGained]
    },
    translatedUseTerms() {
      let mapper = {
        oneShot: 'One Shot',
        oneShotRange: 'One Shot Range',
        range: 'Range',
        untillEnd: 'Esaurimento',
        untillEndRange: 'Esaurimento Range'
      }

      let result = mapper[this.value.useTerms]
      
      if(this.value.useTerms == 'oneShotRange' || this.value.useTerms == 'range' || this.value.useTerms == 'untillEndRange') {
        if(this.value.rangeMode == 'dates')
          result += ` (dal ${this.dateToString('dd/mm/yyyy', new Date(this.value.startDate))} al ${this.dateToString('dd/mm/yyyy', new Date(this.value.expirationDate))}) `
        else if(!this.promoCustomer)
          result += ` (${this.value.duration} giorni) `
        else{
          let passedDays =  Math.floor(((new Date()) - (new Date(this.promoCustomer.createdAt))) / 8.64e7);
          let remain = this.value.duration - passedDays
          if(remain != 0) result += ` (${remain} giorni rimanenti) `
          else result += ` (durata infinita)`
        }
      }
      return result
    },
    useTermsIcon() {
      let mapper = {
        oneShot: 'mdi-clipboard-text-clock-outline',
        oneShotRange: 'mdi-store-clock',
        range: 'mdi-all-inclusive',
        untillEnd: 'mdi-clock-end',
      }

      return mapper[this.value.useTerms]
    },
    translatedItems(){
      if(this.value.allServicesValid && this.value.allItemsValid)
        return this.itemsType[0]
      else if(this.value.allServicesValid)
        return this.itemsType[1]
      else if(this.value.allItemsValid && this.value.gainedItems.length == 0)
        return this.itemsType[2]
      else {
        let res = []
        if (!!this.value.allItemsValid) {
          res.push(this.itemsType[2])
        }
        for(let item of this.value.gainedItems) {
          let remainingQuantity = this.getRemain(item)
          if(remainingQuantity !== null && remainingQuantity !== undefined) {
            const remainToWrite = this.getRemain(item) === Infinity ? 'Illimitato' : this.getRemain(item)
            res.push(item.name + ': ' +  remainToWrite)
          } else {
            res.push(item.name)
          }
          
        }
        return res.join(', ')
      }
    }
  },
}
</script>

<style>

</style>