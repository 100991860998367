<template>
  <v-form v-model="valid">
    <v-row class="mt-2">
      <v-col cols="4">
        <OperatorsAutocomplete
          v-if="!setOperator"
          v-model="localValue.operatorId"
          item-value="id"
          :multiple="false"
          label="Operatore"
          @input="emitValueChange"
        ></OperatorsAutocomplete>
        <OperatorsAutocomplete
          v-else
          v-model="localValue.operator"
          return-object
          :multiple="false"
          label="Operatore"
          @input="emitValueChange"
        ></OperatorsAutocomplete>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-4">
      <v-col class="py-0 pl-0">
        <v-textarea
          class="blue-background"
          v-model="localValue.saloonShampoo"
          filled
          :label="$translations.t('models.DataSheet.fields.saloonShampoo')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="py-0 pl-0">
        <v-textarea
          class="blue-background"
          v-model="localValue.saloonTreatment"
          filled
          :label="$translations.t('models.DataSheet.fields.saloonTreatment')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="py-0 pl-0">
        <v-textarea
          class="green-background"
          v-model="localValue.homeAdvices"
          filled
          :label="$translations.t('models.DataSheet.fields.homeAdvices')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-4">
      <v-col class="py-0 pl-0">
        <v-textarea
          class="red-background"
          v-model="localValue.color"
          filled
          :label="$translations.t('models.DataSheet.fields.color')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="py-0 pl-0">
        <v-textarea
          class="red-background"
          v-model="localValue.balayage"
          filled
          :label="$translations.t('models.DataSheet.fields.balayage')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="py-0 pl-0">
        <v-textarea
          class="blue-background"
          v-model="localValue.hairStyle"
          filled
          :label="$translations.t('models.DataSheet.fields.hairStyle')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-4">
      <v-col class="py-0 pl-0">
        <v-textarea
          class="blue-background"
          v-model="localValue.hairCut"
          filled
          :label="$translations.t('models.DataSheet.fields.hairCut')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="py-0 pl-0">
        <v-textarea
          class="blue-background"
          v-model="localValue.styling"
          filled
          :label="$translations.t('models.DataSheet.fields.styling')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="py-0 pl-0">
        <v-textarea
          class="blue-background"
          v-model="localValue.permanent"
          filled
          :label="$translations.t('models.DataSheet.fields.permanent')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-4">
      <v-col class="py-0 pl-0">
        <v-textarea
          class="blue-background"
          v-model="localValue.various"
          filled
          :label="$translations.t('models.DataSheet.fields.various')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
      <v-col class="py-0 pl-0">
        <v-textarea
          class="blue-background"
          v-model="localValue.notes"
          filled
          :label="$translations.t('models.DataSheet.fields.notes')"
          hide-details="auto"
          @input="emitValueChange"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ComboAdder from '@/components/common/ComboAdder.vue'
import { DateTimePicker } from "likablehair-ui-components"
import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete.vue'

export default {
  name: "BarberDataSheetForm",
  components: {
    DateTimePicker,
    OperatorsAutocomplete,
    ComboAdder
  },
  data: function() {
    return {
      localValue: {...this.value},
      valid: false
    }
  },
  props: {
    value: { },
    setOperator: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitValueChange() {
      this.$emit('input', this.localValue)
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue
    }
  }
}
</script>

<style>
  .green-background > .v-input__control > .v-input__slot {
    background: #22ff000f !important;
  }

  .purple-background > .v-input__control > .v-input__slot {
    background: #9500ff0f !important;
  }

  .pink-background > .v-input__control > .v-input__slot {
    background: #ff00b70f !important;
  }

  .blue-background > .v-input__control > .v-input__slot {
    background: #001eff0f !important;
  }

  .red-background > .v-input__control > .v-input__slot {
    background: #ff00000f !important;
  }

  .yellow-background > .v-input__control > .v-input__slot {
    background: #ffea000f !important;
  }

  .orange-background > .v-input__control > .v-input__slot {
    background: #ff99000f !important;
  }
</style>