<template>
  <div>
    <v-row>
      <v-col>
        <div class="text-h6 ma-1">Promozioni attive</div>
          <div>
            <v-list>
              <div
                v-for="promoCustomer in localPromosCustomers" 
                :key="promoCustomer.id"
              >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :class="{'red--text': promoCustomer.selected == 1}" v-text="promoCustomer.promo.name"></v-list-item-title>
                  <v-list-item-subtitle>
                    <PromoInfo
                      class="mt-2"
                      :value="promoCustomer.promo"
                      :promo-customer="promoCustomer"
                    ></PromoInfo>
                  </v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>
              <v-divider></v-divider>
            </div>
          </v-list>
        </div>
      </v-col>  
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h6 ma-1">Promozioni scadute</div>
          <div>
            <v-list>
              <div
                v-for="promoCustomer in localOldPromosCustomers" 
                :key="promoCustomer.id"
              >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :class="{'red--text': promoCustomer.selected == 1}" v-text="promoCustomer.promo.name"></v-list-item-title>
                  <v-list-item-subtitle>
                    <PromoInfo
                      class="mt-2"
                      :value="promoCustomer.promo"
                      :promo-customer="promoCustomer"
                    ></PromoInfo>
                  </v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>
              <v-divider></v-divider>
            </div>
          </v-list>
        </div>
      </v-col>  
    </v-row>
  </div>
</template>

<script>
import customerForm from "@/services/customers/customer.form.js";
import customerService from '@/services/customers/customers.service.js'
import PromoInfo from '@/components/areas/registries/customers/promos/PromosInfo';

export default {
  name: "PromoFormTab",
  components: {
    PromoInfo
  },
  props: {
  },
  data: function () {
    return {
      localPromosCustomers: [],
      localOldPromosCustomers: [],
      selectedCustomerId: undefined,
      loading: true,
    };
  },
  mounted: function () {
    this.updateCustomerId();
    customerForm.on("update", this.updateCustomerId);
    this.$once("hook:beforeDestroy", function () {
      customerForm.off("update", this.updateCustomerId);
    });
  },
  methods: {
    updateCustomerId() {
      if (
        this.selectedCustomerId != customerForm.customer.id &&
        !!customerForm.customer.id
      ) {
        this.loading = true
        this.selectedCustomerId = customerForm.customer.id;
        this.fetchCustomer().then((result) => {
          this.localPromosCustomers = result.active.promosCustomers;
          this.localOldPromosCustomers = result.old.promosCustomers;
          this.loading = false
        });
      }
      else
        this.loading = false
    },
    fetchCustomer() {
      return new Promise((resolve, reject) => {
        customerService.getCustomerPromosById(this.selectedCustomerId).then((results) => {
          resolve(results);
        });
      });
    },
  },
  watch: {
    page() {
      this.fetchCustomer();
    },
    rowPerPage() {
      this.fetchCustomer();
    },
  },
};
</script>

<style>

</style>