import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class NeedleChoicesService {
  list(page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/needleChoices/list", {
          page: page,
          rowPerPage: rowPerPage,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  create(needleChoice) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/needleChoices/create", needleChoice)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  update(needleChoice) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!needleChoice || !needleChoice.id) {
        reject(new Error("needle choice not specified"));
      }

      axios
        .post(apiUrl + "/registries/needleChoices/update", needleChoice)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  delete(needleChoice) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!needleChoice || !needleChoice.id) {
        reject(new Error("needle choice not specified"));
      }

      axios
        .get(apiUrl + "/registries/needleChoices/delete", {
          id: needleChoice.id,
        })
        .then((response) => {
          if (!response.success) {
            reject(response.results);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  get(needleChoiceId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/needleChoices/get", { id: needleChoiceId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  fields() {
    return Promise.resolve([
      { text: "Nome", value: "name" },
    ]);
  }
}

export default new NeedleChoicesService();
