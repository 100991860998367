<template>
  <div class="pa-0" style="overflow-y: scroll; height: calc(100vh - 64px - 48px - 48px - 30px)"> 
    <div class="stick-title">
      <v-tabs color="grey" v-model="tab">
        <v-tab v-for="element in customerTabs" 
          :key="element.key" 
          :class="element.completed ? 'tabCompleted' : 'tabUncompleted'"
          :style="element.key == tab ? 'background-color: #E8E8E8;' : ''"
        >{{ element.title }}</v-tab>
        <v-tabs-slider color="black2" ></v-tabs-slider>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tabItem in customerTabs" :key="tabItem.key" eager>
        <v-card flat>
          <component :completed.sync="tabItem.completed" class="pa-1" :is="tabItem.component" ></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import BeautyConfidentialDataSheet from "../dataSheetsLastVersion/BeautyConfidentialDataSheet.vue";
import BeautyFaceCheckupDataSheet from "../dataSheetsLastVersion/BeautyFaceCheckupDataSheet.vue";
import BeautyBodyCheckupDataSheet from "../dataSheetsLastVersion/BeautyBodyCheckupDataSheet.vue";
import BeautyEpilationDataSheet from "../dataSheetsLastVersion/BeautyEpilationDataSheet.vue";
import BeautyExtentionDataSheet from "../dataSheetsLastVersion/BeautyExtentionDataSheet.vue";
import BeautyNailsDataSheet from "../dataSheetsLastVersion/BeautyNailsDataSheet.vue";
import BeautyWorksDataSheet from "../dataSheetsLastVersion/BeautyWorksDataSheet.vue";

export default {
  name: "BeautyDataSheet",
  components: {
    BeautyConfidentialDataSheet,
    BeautyFaceCheckupDataSheet,
    BeautyBodyCheckupDataSheet,
    BeautyExtentionDataSheet,
    BeautyEpilationDataSheet,
    BeautyNailsDataSheet,
    BeautyWorksDataSheet,
  },
  data: function () {
    return {
      tab: null,
      infoTabs: undefined,
      customerTabs: [
        {
          key: 0,
          title: "Confidenziale",
          name: "beautyConfidential",
          component: "BeautyConfidentialDataSheet",
          completed: false,
        },
        {
          key: 1,
          title: "Check-Up Corpo",
          name: "beautyBodyCheckup",
          component: "BeautyBodyCheckupDataSheet",
          completed: false,
        },
        {
          key: 2,
          title: "Check-Up Viso",
          name: "beautyConfidential",
          component: "BeautyFaceCheckupDataSheet",
          completed: false,
        },
        {
          key: 3,
          title: "Extention Ciglia",
          name: "beautyExtention",
          component: "BeautyExtentionDataSheet",
          completed: false,
        },
        {
          key: 4,
          title: "Epilazione",
          name: "beautyEpilation",
          component: "BeautyEpilationDataSheet",
          completed: false,
        },
        {
          key: 5,
          title: "Manicure",
          name: "beautyNails",
          component: "BeautyNailsDataSheet",
          completed: false,
        },
        {
          key: 6,
          title: "Programmi Lavoro",
          name: "beautyWorks",
          component: "BeautyWorksDataSheet",
          completed: false,
        }
      ],
    };
  },
  mounted: function () {
  },
  methods: {
    /**
     * Check if all the fields are completed
     * @param {Object} infoTab - The info tab to check
     */
    checkAllCompleted(infoTab){
      if(infoTab[0] == 'version' || infoTab[0] == 'id' || infoTab[0] == 'customerId' || infoTab[0] == 'createdAt')
        return
      else if(infoTab[0] == 'barberColoring'){
        let completed = !!infoTab[1].data[0].date
        if(completed){
          let customerTabToUpdate = this.customerTabs.find(el => el.name == infoTab[0])
          if(!!customerTabToUpdate) customerTabToUpdate.completed = true
        }
      }  
      else {
        let completed = true
        for (const el of infoTab[1].data){ 
          if(el.value == undefined || (Array.isArray(el.value) && el.value.length == 0)){
            completed = false
            break  
          }   
        }
        if(completed){
          let customerTabToUpdate = this.customerTabs.find(el => el.name == infoTab[0])
          if(!!customerTabToUpdate) customerTabToUpdate.completed = true
        }
      }
    },
  },
};
</script>

<style>
.v-tab.tabUncompleted {
  color: red !important;
}
.v-tab.tabCompleted {
  color: green !important;
}
.stick-title {
  position: sticky;
  top: 0;
  z-index: 5;
}
</style>