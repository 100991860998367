import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class diaryCustomerService {

  getData(customerId, ghost) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/customers/diary", { id: customerId, ghost: ghost })
        .then((response) => {
          if (response.success) {
            for (let i = 0; i < response.results.items.length; i++) {
              response.results.services.push({
                service: { name: response.results.items[i].item.description },
                billServiceOperators: response.results.items[i].billItemOperators,
                bill: response.results.items[i].bill,
                type: "item",
              })
            }

            resolve(response.results.services.sort((a, b) => {
              return new Date(b.bill.openedAt) - new Date(a.bill.openedAt)
            }));
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }
}

export default new diaryCustomerService();