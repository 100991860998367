<template>
  <v-row no-gutters class="py-1">
    <v-col cols="auto">
      <LeftSideMenu
        v-model="selectedItem"
        :items="menuItems"
        identifier-key="name"
        @item-click="handleMenuItemsClick"
        divided
      ></LeftSideMenu>
    </v-col>
    <v-col class="pa-3">
      <component 
        :is="selectedItem.component"
        :customer="customer"
      ></component>
    </v-col>
  </v-row>
</template>

<script>
import BeuticianDataSheetsList from '../dataSheets/BeuticianDataSheetsList.vue'
import PmuDataSheetsList from '../dataSheets/PmuDataSheetsList.vue'
import BarberDataSheetsList from '../dataSheets/BarberDataSheetsList.vue'
import LeftSideMenu from "@/components/common/LeftSideMenu.vue";
import customerForm from '@/services/customers/customer.form.js'

export default {
  name: 'DataSheetFormTab',
  components: {
    BeuticianDataSheetsList,
    PmuDataSheetsList,
    BarberDataSheetsList,
    LeftSideMenu
  },
  data: function() {
    return {
      selectedItem: {
          title: "Parrucchiere",
          name: "barber",
          component: "BarberDataSheetsList",
      },
      customer: {},
      menuItems: [
        {
          title: "Parrucchiere",
          name: "barber",
          component: "BarberDataSheetsList",
        },
        {
          title: "Estetisti",
          name: "beutician",
          component: "BeuticianDataSheetsList",
        },
        {
          title: "PMU",
          name: "pmu",
          component: "PmuDataSheetsList",
        },
      ]
    }
  },
  mounted: function() {
    let self = this
    this.customer = customerForm.customer
    customerForm.on('update', function(data) {
      self.customer = customerForm.customer
    })
  },
  methods: {
    handleMenuItemsClick(item) {
      this.selectedItem = item
    }
  }
}
</script>

<style>

</style>