<template>
  <TypeDataTable
    :headers="localHeaders"
    :items="localItems"
    :showSelect="false"
    :showActions="false"
    :loading="loading"
    :page.sync="page"
    :row-per-page.sync="rowPerPage"
    :total-pages.sync="totalPages"
  >
    <template v-slot:custom-typeTransaction="{ item }">
      <v-chip v-if="item.amount < 0" color="red">
        {{'Scontrino'}}
      </v-chip>  
      <v-chip v-else-if="item.amount > 0 && !!item.info && !item.info.fromDebit" :color="'green'">
        {{'Ricarica Tessera'}}
      </v-chip> 
      <v-chip v-else-if="item.amount > 0 && !!item.info && !!item.info.fromDebit" color="orange">
        {{'Ricarica Tessera Da Storno'}}
      </v-chip> 
    </template>
    <template v-slot:custom-date="{ item }">
      <DateTimeFormattor :value="item.createdAt"> </DateTimeFormattor>
    </template>
    <template v-slot:custom-amount="{ item }">
      {{!!item.customerCardPaid ? item.customerCardPaid + ' €' : item.amount + ' €'}}
    </template>
    <template v-slot:custom-oldRemaining="{ item }">
      {{!!item.info.remaining ? item.info.remaining + ' €' : ''}}
    </template>

    <template v-slot:externalFooter>
      <TypeDataTable
        :headers="totalHeaders"
        :items="totalRow"
        :show-select="false"
        :show-actions="false"
        disablePagination
        hideDefaultHeader
      >
        <template v-slot:custom-text="{value}">
          {{value}}
        </template> 
      </TypeDataTable>
    </template>
  </TypeDataTable>
</template>

<script>
import customerForm from "@/services/customers/customer.form.js";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";
import customerService from '@/services/customers/customers.service.js'

export default {
  name: "CardHistoryFormTab",
  components: {
    TypeDataTable,
    DateTimeFormattor,
  },
  props: {
  },
  data: function () {
    return {
      localHeaders: [
        { text: "Tipo Transazione", value: "typeTransaction", type: "custom" },
        { text: "Importo", value: "amount",type: "custom", },
        { text: "Saldo", value: "oldRemaining", type: "custom" },
        { text: "Data", value: "date", type: "custom" },
      ],
      localItems: [],
      selectedCustomerId: undefined,
      loading: true,
      page: 1,
      rowPerPage: 20,
      totalPages: 100,
      totalRow: undefined,
      totalHeaders: [        
          { text: "Text", value: "text", type: "custom" },
          { text: "Importo", value: "sum", type: "price" }
        ]
    };
  },
  mounted: function () {
    this.updateCustomerId();
    customerForm.on("update", this.updateCustomerId);
    this.$once("hook:beforeDestroy", function () {
      customerForm.off("update", this.updateCustomerId);
    });
  },
  methods: {
    updateCustomerId() {
      if (
        this.selectedCustomerId != customerForm.customer.id &&
        !!customerForm.customer.id
      ) {
        this.loading = true
        this.selectedCustomerId = customerForm.customer.id;
        this.fetchCustomer().then((result) => {
          this.localItems = result;
          this.loading = false
        });
      }
      else 
        this.loading = false
    },
    fetchCustomer() {
      return new Promise((resolve, reject) => {
        customerService.listCustomerCardPayments(this.selectedCustomerId, this.page, this.rowPerPage).then((results) => {
           if (this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage;
          }

          if (this.page != results.page) {
            this.page = results.page;
          }

          this.totalPages = results.totalPages;
          if (this.totalPages < this.page) {
            this.page = this.totalPages;
          }

          this.totalRow = results.totalRow;

          resolve(results.rows);
        });
      });
    },
  },
  watch: {
    page() {
      this.fetchCustomer();
    },
    rowPerPage() {
      this.fetchCustomer();
    },
  },
};
</script>

<style>
</style>