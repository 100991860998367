<template>
  <div>
    <div v-for="(item, indexInfoTab) in infoTabs" :key="item.key">
      <div
        style="color: red; font-size: 20px"
      >{{item.title}}
      </div>
      <div v-for="(element, indexInfoTabElement) in item.items" :key="element.key">
        <OpenQuestion
          v-if="element.type == 'openQuestion'"
          :question="element.question"
          :value="element.value"
          :label="element.label"
          @valueChanged="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event
            updateCustomerForm()
          }"
        ></OpenQuestion>  
        <CheckBoxQuestion
          v-else-if="element.type == 'checkbox'"
          :value="element.value"
          :question="element.question"
          :label="element.label"
          :checkboxes="element.checkboxes"
          :textfield="element.textfield"
          @checkboxClicked="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event.value
            updateCustomerForm()
          }"
          @textfieldChanged="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].textfield = $event
            updateCustomerForm()
          }"
        ></CheckBoxQuestion>  
        <TableQuestion
          v-else-if="element.type == 'table'"
          :items="element.value"
          @valueChanged="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event
            updateCustomerForm()
          }"
        ></TableQuestion>  
      </div>
    </div>
  </div>
</template>

<script>
import formsDataSheet from './FormsDataSheetJson.js'
import customerForm from "@/services/customers/customer.form.js";
import OpenQuestion from "./components/OpenQuestion.vue";
import CheckBoxQuestion from "./components/CheckBoxQuestion.vue";
import TableQuestion from "./components/TableQuestion.vue";

export default {
  name: 'BeautyEpilationDataSheet',
  components: {
    OpenQuestion,
    CheckBoxQuestion,
    TableQuestion,
  },
  data: function() {
    return {
      newForm: new formsDataSheet(),
      infoTabs: undefined,
      loading: false,
    }
  },
  props: {
    completed:{
      type: Boolean,
      default: false,
    },
  },
  mounted: function() {
    if(!customerForm.customer.beautyDataSheet){
      customerForm.updateField("beautyDataSheet", this.newForm.beautyForm);
      this.infoTabs = [...this.newForm.beautyForm.beautyEpilation]
    }
    else if(!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyEpilation){
      this.infoTabs = !!customerForm.customer.beautyDataSheet.beautyEpilation.data ? [...customerForm.customer.beautyDataSheet.beautyEpilation.data] : [...customerForm.customer.beautyDataSheet.beautyEpilation]
    }

    this.updateCustomerForm()
  },
  methods: {
    /**
     * Update customer form Object
     */
    updateCustomerForm() {
      if (!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyEpilation) {
        customerForm.updateObjectField("beautyDataSheet","beautyEpilation", this.infoTabs);
      } else if(!!customerForm.customer.beautyDataSheet) {
        // Creation case
        customerForm.updateObjectField("beautyDataSheet","beautyEpilation", this.infoTabs);
      }
      else {
        console.log('Error in updateLocalField');
      }
      this.checkAllCompleted()
    },
    /**
     * Check if all the fields are completed
     */
    checkAllCompleted(){
      let completed = false
      completed = this.infoTabs.some((tab) => {
        return tab.items.some((item) => {
          if (!!item.checkboxes) {
            return (item.value !== null && item.value !== undefined && item.value !== "") ||
              (item.textfield !== null && item.textfield !== undefined && item.textfield !== "")
          }
          if (Array.isArray(item.value))
            return item.value.some(el => (el.value !== null && el.value !== undefined && el.value !== ""))
          return item.value !== null && item.value !== undefined && item.value !== ""
        })
      })
      this.$emit('update:completed', completed)
    },
  },
  watch: {
  }
}
</script>

<style scoped>
</style>