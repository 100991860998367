<template>
  <div>
    <div v-for="(item, indexInfoTab) in infoTabs" :key="item.key">
      <div
        style="color: red; font-size: 20px"
      >{{item.title}}
      </div>
      <div v-for="(element, indexInfoTabElement) in item.items" :key="element.key">
        <OpenQuestion
          v-if="element.type == 'openQuestion'"
          :question="element.question"
          :value="element.value"
          :label="element.label"
          @valueChanged="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event
            updateCustomerForm()
          }"
        ></OpenQuestion>  
        <CheckBoxQuestion
          v-else-if="element.type == 'checkbox'"
          :value="element.value"
          :question="element.question"
          :label="element.label"
          :textfield="element.textfield"
          :checkboxes="element.checkboxes"
          @checkboxClicked="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = $event.value
            updateCustomerForm()
          }"
          @textfieldChanged="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].textfield = $event
            updateCustomerForm()
          }"
        ></CheckBoxQuestion>  
        <TableQuestion
          v-else-if="element.type == 'table'"
          :items="element.value"
        ></TableQuestion>  
        <TableBeautyBodyImage
          v-else-if="element.type == 'bodyMeasures'"
          :cmMeasures="element.cmMeasures"
          :arms ="element.arms"
          :chest="element.chest"
          :waist="element.waist"
          :wrist="element.wrist"
          :flanks="element.flanks"
          :thigh="element.thigh"
          :knee="element.knee"
          :calf="element.calf"
          :ankle="element.ankle"
          @cmMeasures="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures = $event
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
              && infoTabs[indexInfoTab].items[indexInfoTabElement].arms
              && infoTabs[indexInfoTab].items[indexInfoTabElement].chest
              && infoTabs[indexInfoTab].items[indexInfoTabElement].waist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
              && infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
              && infoTabs[indexInfoTab].items[indexInfoTabElement].knee
              && infoTabs[indexInfoTab].items[indexInfoTabElement].calf
              && infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
            updateCustomerForm()
          }"
          @arms="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].arms = $event
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
              && infoTabs[indexInfoTab].items[indexInfoTabElement].arms
              && infoTabs[indexInfoTab].items[indexInfoTabElement].chest
              && infoTabs[indexInfoTab].items[indexInfoTabElement].waist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
              && infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
              && infoTabs[indexInfoTab].items[indexInfoTabElement].knee
              && infoTabs[indexInfoTab].items[indexInfoTabElement].calf
              && infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
            updateCustomerForm()
          }"
          @chest="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].chest = $event
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
              && infoTabs[indexInfoTab].items[indexInfoTabElement].arms
              && infoTabs[indexInfoTab].items[indexInfoTabElement].chest
              && infoTabs[indexInfoTab].items[indexInfoTabElement].waist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
              && infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
              && infoTabs[indexInfoTab].items[indexInfoTabElement].knee
              && infoTabs[indexInfoTab].items[indexInfoTabElement].calf
              && infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
            updateCustomerForm()
          }"
          @waist="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].waist = $event
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
              && infoTabs[indexInfoTab].items[indexInfoTabElement].arms
              && infoTabs[indexInfoTab].items[indexInfoTabElement].chest
              && infoTabs[indexInfoTab].items[indexInfoTabElement].waist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
              && infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
              && infoTabs[indexInfoTab].items[indexInfoTabElement].knee
              && infoTabs[indexInfoTab].items[indexInfoTabElement].calf
              && infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
            updateCustomerForm()
          }"
          @wrist="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].wrist = $event
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
              && infoTabs[indexInfoTab].items[indexInfoTabElement].arms
              && infoTabs[indexInfoTab].items[indexInfoTabElement].chest
              && infoTabs[indexInfoTab].items[indexInfoTabElement].waist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
              && infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
              && infoTabs[indexInfoTab].items[indexInfoTabElement].knee
              && infoTabs[indexInfoTab].items[indexInfoTabElement].calf
              && infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
            updateCustomerForm()
          }"
          @flanks="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].flanks = $event
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
              && infoTabs[indexInfoTab].items[indexInfoTabElement].arms
              && infoTabs[indexInfoTab].items[indexInfoTabElement].chest
              && infoTabs[indexInfoTab].items[indexInfoTabElement].waist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
              && infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
              && infoTabs[indexInfoTab].items[indexInfoTabElement].knee
              && infoTabs[indexInfoTab].items[indexInfoTabElement].calf
              && infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
            updateCustomerForm()
          }"
          @thigh="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].thigh = $event
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
              && infoTabs[indexInfoTab].items[indexInfoTabElement].arms
              && infoTabs[indexInfoTab].items[indexInfoTabElement].chest
              && infoTabs[indexInfoTab].items[indexInfoTabElement].waist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
              && infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
              && infoTabs[indexInfoTab].items[indexInfoTabElement].knee
              && infoTabs[indexInfoTab].items[indexInfoTabElement].calf
              && infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
            updateCustomerForm()
          }"
          @knee="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].knee = $event
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
              && infoTabs[indexInfoTab].items[indexInfoTabElement].arms
              && infoTabs[indexInfoTab].items[indexInfoTabElement].chest
              && infoTabs[indexInfoTab].items[indexInfoTabElement].waist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
              && infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
              && infoTabs[indexInfoTab].items[indexInfoTabElement].knee
              && infoTabs[indexInfoTab].items[indexInfoTabElement].calf
              && infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
            updateCustomerForm()
          }"
          @calf="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].calf = $event
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
              && infoTabs[indexInfoTab].items[indexInfoTabElement].arms
              && infoTabs[indexInfoTab].items[indexInfoTabElement].chest
              && infoTabs[indexInfoTab].items[indexInfoTabElement].waist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
              && infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
              && infoTabs[indexInfoTab].items[indexInfoTabElement].knee
              && infoTabs[indexInfoTab].items[indexInfoTabElement].calf
              && infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
            updateCustomerForm()
          }"
          @ankle="($event) => {
            infoTabs[indexInfoTab].items[indexInfoTabElement].ankle = $event
            infoTabs[indexInfoTab].items[indexInfoTabElement].value = infoTabs[indexInfoTab].items[indexInfoTabElement].cmMeasures 
              && infoTabs[indexInfoTab].items[indexInfoTabElement].arms
              && infoTabs[indexInfoTab].items[indexInfoTabElement].chest
              && infoTabs[indexInfoTab].items[indexInfoTabElement].waist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].wrist
              && infoTabs[indexInfoTab].items[indexInfoTabElement].flanks
              && infoTabs[indexInfoTab].items[indexInfoTabElement].thigh
              && infoTabs[indexInfoTab].items[indexInfoTabElement].knee
              && infoTabs[indexInfoTab].items[indexInfoTabElement].calf
              && infoTabs[indexInfoTab].items[indexInfoTabElement].ankle ? true : undefined
            updateCustomerForm()
          }"
        ></TableBeautyBodyImage>  
      </div>
    </div>
  </div>
</template>

<script>
import formsDataSheet from './FormsDataSheetJson.js'
import customerForm from "@/services/customers/customer.form.js";
import OpenQuestion from "./components/OpenQuestion.vue";
import CheckBoxQuestion from "./components/CheckBoxQuestion.vue";
import TableQuestion from "./components/TableQuestion.vue";
import TableBeautyBodyImage from "./components/TableBeautyBodyImage.vue";

export default {
  name: 'BeautyBodyCheckupDataSheet',
  components: {
    OpenQuestion,
    CheckBoxQuestion,
    TableQuestion,
    TableBeautyBodyImage,
  },
  data: function() {
    return {
      newForm: new formsDataSheet(),
      infoTabs: undefined,
      loading: false,
    }
  },
  props: {
    completed:{
      type: Boolean,
      default: false,
    },
  },
  mounted: function() {
    if(!customerForm.customer.beautyDataSheet){
      customerForm.updateField("beautyDataSheet", this.newForm.beautyForm);
      this.infoTabs = [...this.newForm.beautyForm.beautyBodyCheckup]
    }
    else if(!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyBodyCheckup){
      this.infoTabs = !!customerForm.customer.beautyDataSheet.beautyBodyCheckup.data ? [...customerForm.customer.beautyDataSheet.beautyBodyCheckup.data] : [...customerForm.customer.beautyDataSheet.beautyBodyCheckup]
    }

    this.updateCustomerForm()

  },
  methods: {
   /**
     * Change checkbox value
     * @param {Object} row
     * @param {string} row.type
     * @param {number} row.value
     * @param {Object} infoTab
     * @param {Array} infoTab.headers
     * @param {Array} infoTab.items
     * @param {number} infoTab.key
     * @param {number|number[]} infoTab.value - the checkbox selected or multiselected
     */
    checkboxClicked(row, infoTab){
      if(typeof(infoTab.value) === 'number' || infoTab.value == undefined){
        (infoTab.value != row.value) ? infoTab.value = Number(row.value) : infoTab.value = undefined  
      }
      else if(Array.isArray(infoTab.value)){
        let find = infoTab.value.find(el => el == row.value)
        find == undefined ? infoTab.value = [...infoTab.value, row.value] : infoTab.value = infoTab.value.filter(el => el != row.value)
      }
      this.updateCustomerForm()
    },
    /**
     * Update customer form Object
     */
    updateCustomerForm() {
      if (!!customerForm.customer.beautyDataSheet && !!customerForm.customer.beautyDataSheet.beautyBodyCheckup) {
        customerForm.updateObjectField("beautyDataSheet","beautyBodyCheckup", this.infoTabs);
      } else if(!!customerForm.customer.beautyDataSheet) {
        // Creation case
        customerForm.updateObjectField("beautyDataSheet","beautyBodyCheckup", this.infoTabs);
      }
      else {
        console.log('Error in updateLocalField');
      }
      this.checkAllCompleted()
    },
    /**
     * Check if all the fields are completed
     */
    checkAllCompleted(){
      let completed = false
      completed = this.infoTabs.some((tab) => {
        return tab.items.some((item) => {
          if (!!item.checkboxes) {
            return (item.value !== null && item.value !== undefined && item.value !== "") ||
              (item.textfield !== null && item.textfield !== undefined && item.textfield !== "")
          }
          if (Array.isArray(item.value))
            return item.value.some(el => (el.value !== null && el.value !== undefined && el.value !== ""))
          if (item.value === undefined) {
            let localCompleted = false
            for (const property in item) {
              if (Array.isArray(item[property]))
                localCompleted = item[property].some(el => el !== null && el !== undefined && el !== "")
              else 
                localCompleted = property != "type" && property != "key" && (item[property] !== null && item[property] !== undefined && item[property] !== "")
              if (!!localCompleted) return localCompleted
            }
            return localCompleted
          }
          return item.value !== null && item.value !== undefined && item.value !== ""
        })
      })
      this.$emit('update:completed', completed)
    },
  },
  watch: {
  }
}
</script>

<style scoped>
</style>